import React from "react";

const paperClip = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.34837 3.83455C8.46359 2.76651 9.97607 2.1665 11.5532 2.1665C13.1304 2.1665 14.643 2.76653 15.7582 3.83458C16.8735 4.90264 17.5 6.35123 17.5 7.86168C17.5 9.3721 16.8736 10.8207 15.7583 11.8888L9.75122 17.6418C8.9546 18.4047 7.8742 18.8332 6.74768 18.8332C5.62112 18.8332 4.54071 18.4046 3.74412 17.6417C2.94752 16.8788 2.5 15.8441 2.5 14.7652C2.5 13.6863 2.9475 12.6516 3.74409 11.8888L9.75122 6.13575C10.2292 5.67797 10.8774 5.42094 11.5533 5.42094C12.2292 5.42094 12.8775 5.67809 13.3554 6.13583C13.8334 6.59357 14.1019 7.21439 14.1019 7.86173C14.1019 8.50902 13.8335 9.12978 13.3555 9.58755L7.34837 15.3406L6.14694 14.19L12.1541 8.43695C12.3133 8.28441 12.4028 8.07756 12.4028 7.86173C12.4028 7.64595 12.3133 7.43901 12.154 7.28643C11.9947 7.13385 11.7786 7.04813 11.5533 7.04813C11.3279 7.04813 11.1119 7.13382 10.9526 7.28635L4.94552 13.0394C4.46756 13.4971 4.19907 14.1179 4.19907 14.7652C4.19907 15.4125 4.46758 16.0333 4.94554 16.4911C5.4235 16.9488 6.07175 17.206 6.74768 17.206C7.42365 17.206 8.07186 16.9489 8.54979 16.4912L14.5569 10.7382C15.3535 9.97528 15.8009 8.94062 15.8009 7.86168C15.8009 6.78278 15.3534 5.74808 14.5568 4.98518C13.7602 4.22229 12.6798 3.7937 11.5532 3.7937C10.4267 3.7937 9.34639 4.22226 8.54979 4.98515L3.74409 9.58755L2.54267 8.43695L7.34837 3.83455Z"
        fill="#2B2D33"
      />
    </svg>
  );
};

export default paperClip;
