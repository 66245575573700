import React, { FC } from "react";
import style from "./styles.module.scss";
import clsx from "clsx";

type Props = {
  value: boolean;
  onClick?: () => void;
};

const Switch: FC<Props> = ({ value, onClick }) => {
  return (
    <div
      title={value ? "Yes" : "No"}
      onClick={onClick}
      className={clsx(style.switch, { [style.active]: value })}
    >
      <div
        className={clsx(style.switch__circle, { [style.active]: value })}
      ></div>
    </div>
  );
};

export default Switch;
