import React, { FC } from "react";

import styles from "./styles.module.scss";

import { DeleteButton, EditButton } from "~components";
import clsx from "clsx";
import { NotificationModel } from "~models";
import moment from "moment";

type Props = {
  isScheduled: boolean;
  notification: NotificationModel;
  onPressDelete: () => void;
};

const NotificationItem: FC<Props> = ({
  isScheduled,
  notification,
  onPressDelete,
}) => {
  return (
    <div className={styles.notificationBlock}>
      <div
        className={clsx(styles.notificationBlock__message, {
          [styles.notificationBlock__messageShort]: isScheduled,
        })}
      >
        {notification.content}
      </div>

      <div className={styles.notificationBlock__time}>
        {/* <div>5:31 PM</div> */}
        <div>{moment(notification.createdAt).format("hh:mm a")}</div>
        {/* <div>Sep 20 2022</div> */}
        <div>{moment(notification.createdAt).format("MMM DD YYYY")}</div>
      </div>

      {isScheduled && (
        <div>
          {/* <EditButton /> */}
          {/* <div className={styles.notificationBlock__deleteButton}> */}
          <DeleteButton onClick={onPressDelete} />
          {/* </div> */}
        </div>
      )}
    </div>
  );
};

export default NotificationItem;
