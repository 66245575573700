import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { iconsPng } from "~assets/png";
import {
  Users,
  Categories,
  Notifications,
  Places,
  Reports,
  ArrowDown,
  Events,
} from "~assets/svg";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { getSuperAdmin, getToken, removeSuperAdmin, removeToken } from "~utils";
import { useNavigate } from "react-router-dom";

const LeftPanel = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [showUserManagementPopup, setShowUserManagementPopup] = useState(false);
  const [showReportsPopup, setShowReportsPopup] = useState(false);

  const isSuperAdmin = getSuperAdmin();

  const onChangeUserPopupState = () => {
    showReportsPopup && !showUserManagementPopup && setShowReportsPopup(false);
    setShowUserManagementPopup(!showUserManagementPopup);
  };

  const onChangeReportPopupState = () => {
    showUserManagementPopup &&
      !showReportsPopup &&
      setShowUserManagementPopup(false);
    setShowReportsPopup(!showReportsPopup);
  };

  const isUserManagementActive =
    location.pathname.includes("/users") ||
    location.pathname.includes("/admins");

  const isReportManagementActive = location.pathname.includes("/reports");

  const getIconsColor = (value: boolean) => {
    return value ? "#FFFFFF" : "#808191";
  };

  return (
    <div className={styles.panel}>
      <div>
        <img src={iconsPng.logo} className={styles.panel__logo} />

        {isSuperAdmin ? (
          <div
            className={clsx(styles.popup, {
              [styles.popup__active]: isUserManagementActive,
            })}
            onClick={onChangeUserPopupState}
          >
            <div
              className={clsx(styles.popup__left, {
                [styles.popup__leftActive]: isUserManagementActive,
              })}
            >
              <Users fill={getIconsColor(isUserManagementActive)} />
              User management
            </div>

            <div
              className={clsx(styles.popup__arrow, {
                [styles.popup__arrowRotate]: showUserManagementPopup,
              })}
            >
              <ArrowDown fill={getIconsColor(isUserManagementActive)} />
            </div>
          </div>
        ) : (
          <NavLink
            className={(navigationData) =>
              navigationData.isActive ? styles.active : null
            }
            to={"/users"}
          >
            <Users fill={getIconsColor(isUserManagementActive)} />
            User management
          </NavLink>
        )}

        {showUserManagementPopup && (
          <>
            <NavLink
              className={(navigationData) =>
                navigationData.isActive
                  ? styles.popup__itemActive
                  : styles.popup__item
              }
              to={"/users"}
            >
              Mobile app users
            </NavLink>
            <NavLink
              className={(navigationData) =>
                navigationData.isActive
                  ? styles.popup__itemActive
                  : styles.popup__item
              }
              to={"/mobile/admins"}
            >
              Mobile app admins
            </NavLink>
            <NavLink
              className={(navigationData) =>
                navigationData.isActive
                  ? styles.popup__itemActive
                  : styles.popup__item
              }
              to={"/admins"}
            >
              Admin platform users
            </NavLink>
          </>
        )}

        <NavLink
          className={(navigationData) =>
            navigationData.isActive ? styles.active : null
          }
          to={"/categories"}
        >
          <Categories
            fill={getIconsColor(location.pathname.includes("/categories"))}
          />
          Categories
        </NavLink>
        {/* 
        <NavLink
          className={(navigationData) =>
            navigationData.isActive ? styles.active : null
          }
          to={"/archtypes"}
        >
          <Categories
            fill={getIconsColor(location.pathname.includes("/archtypes"))}
          />
          Archtypes
        </NavLink> */}

        <NavLink
          className={(navigationData) =>
            navigationData.isActive ? styles.active : null
          }
          to={"/events"}
        >
          <Events
            stroke={getIconsColor(location.pathname.includes("/events"))}
          />
          Events
        </NavLink>

        <NavLink
          className={(navigationData) =>
            navigationData.isActive ? styles.active : null
          }
          to={"/notifications"}
        >
          <Notifications
            fill={getIconsColor(location.pathname === "/notifications")}
          />
          Notifications
        </NavLink>
        <NavLink
          className={(navigationData) =>
            navigationData.isActive ? styles.active : null
          }
          to={"/places"}
        >
          <Places fill={getIconsColor(location.pathname.includes("/places"))} />
          Places management
        </NavLink>
        {/* <NavLink
          className={(navigationData) =>
            navigationData.isActive ? styles.active : null
          }
          to={"/reports"}
        >
          <Reports fill={getIconsColor(location.pathname === "/reports")} />
          Reports
        </NavLink> */}

        <div
          className={clsx(styles.popup, {
            [styles.popup__active]: isReportManagementActive,
          })}
          onClick={onChangeReportPopupState}
        >
          <div
            className={clsx(styles.popup__left, {
              [styles.popup__leftActive]: isReportManagementActive,
            })}
          >
            <Users fill={getIconsColor(isReportManagementActive)} />
            Reports
          </div>

          <div
            className={clsx(styles.popup__arrow, {
              [styles.popup__arrowRotate]: showReportsPopup,
            })}
          >
            <ArrowDown fill={getIconsColor(isReportManagementActive)} />
          </div>
        </div>

        {showReportsPopup && (
          <>
            <NavLink
              className={(navigationData) =>
                navigationData.isActive
                  ? styles.popup__itemActive
                  : styles.popup__item
              }
              to={"/reports/comment"}
            >
              Reported comments
            </NavLink>
            <NavLink
              className={(navigationData) =>
                navigationData.isActive
                  ? styles.popup__itemActive
                  : styles.popup__item
              }
              to={"/reports/user"}
            >
              Reported users
            </NavLink>
            <NavLink
              className={(navigationData) =>
                navigationData.isActive
                  ? styles.popup__itemActive
                  : styles.popup__item
              }
              to={"/reports/general"}
            >
              General reports
            </NavLink>
            <NavLink
              className={(navigationData) =>
                navigationData.isActive
                  ? styles.popup__itemActive
                  : styles.popup__item
              }
              to={"/reports/feedback"}
            >
              Feedbacks
            </NavLink>
          </>
        )}
      </div>

      <div
        onClick={() => {
          removeToken();
          removeSuperAdmin();
          navigate("/login");
        }}
        className={styles.panel__logout}
      >
        Log out
      </div>
    </div>
  );
};
export default LeftPanel;
