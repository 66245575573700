import Users from "./users";
import Categories from "./categories";
import Notifications from "./notifications";
import Places from "./places";
import Reports from "./reports";
import ArrowDown from "./arrowDown";
import PlusSquare from "./plusSquare";
import Search from "./search";
import ImportIcon from "./importIcon";
import Edit from "./edit";
import DeleteIcon from "./delete";
import PaginationArrow from "./paginationArrow";
import ArrowLeft from "./arrowLeft";
import PaperClip from "./paperClip";
import CalendarArrow from "./calendarArrow";
import Comment from "./comment";
import Close from "./close";
import EditPen from "./editPen";
import Events from "./events";
import Plus from "./plus";
import MapMakrer from "./mapMakrer";
import Minuse from "./minuse";
import PlusIcon from "./plusIcon";
import ViewIcon from "./view";

export {
  Users,
  Categories,
  Notifications,
  Places,
  Reports,
  ArrowDown,
  PlusSquare,
  Search,
  ImportIcon,
  Edit,
  DeleteIcon,
  PaginationArrow,
  ArrowLeft,
  PaperClip,
  CalendarArrow,
  Comment,
  Close,
  EditPen,
  Events,
  Plus,
  MapMakrer,
  Minuse,
  PlusIcon,
  ViewIcon,
};
