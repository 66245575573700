import React from "react";

type Props = {
  fill: string;
};

const categories = ({ fill }: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16732 2.33325C2.33398 2.33325 2.33398 2.47192 2.33398 4.16659V4.18325C2.33398 4.92125 2.33398 5.45459 2.51465 5.67992C2.69132 5.89859 3.21598 5.99992 4.16732 5.99992C5.11865 5.99992 5.64332 5.89792 5.81998 5.67925C6.00065 5.45459 6.00065 4.92125 6.00065 4.18259C6.00065 2.47192 6.00065 2.33325 4.16732 2.33325ZM4.16732 6.99992C3.04332 6.99992 2.19998 6.88192 1.73665 6.30658C1.33398 5.80725 1.33398 5.12592 1.33398 4.18325L1.83398 4.16659H1.33398C1.33398 2.25325 1.45465 1.33325 4.16732 1.33325C6.87998 1.33325 7.00065 2.25325 7.00065 4.16659C7.00065 5.12525 7.00065 5.80725 6.59798 6.30658C6.13465 6.88192 5.29132 6.99992 4.16732 6.99992Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5013 2.33325C9.66797 2.33325 9.66797 2.47192 9.66797 4.16659V4.18325C9.66797 4.92125 9.66797 5.45459 9.84864 5.67992C10.0253 5.89859 10.55 5.99992 11.5013 5.99992C12.4526 5.99992 12.9773 5.89792 13.154 5.67925C13.3346 5.45459 13.3346 4.92125 13.3346 4.18259C13.3346 2.47192 13.3346 2.33325 11.5013 2.33325ZM11.5013 6.99992C10.3773 6.99992 9.53397 6.88192 9.07064 6.30658C8.66797 5.80725 8.66797 5.12592 8.66797 4.18325L9.16797 4.16659H8.66797C8.66797 2.25325 8.78864 1.33325 11.5013 1.33325C14.214 1.33325 14.3346 2.25325 14.3346 4.16659C14.3346 5.12525 14.3346 5.80725 13.932 6.30658C13.4686 6.88192 12.6253 6.99992 11.5013 6.99992Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16732 9.6665C2.33398 9.6665 2.33398 9.80517 2.33398 11.4998V11.5165C2.33398 12.2545 2.33398 12.7878 2.51465 13.0132C2.69132 13.2318 3.21598 13.3332 4.16732 13.3332C5.11865 13.3332 5.64332 13.2312 5.81998 13.0125C6.00065 12.7878 6.00065 12.2545 6.00065 11.5158C6.00065 9.80517 6.00065 9.6665 4.16732 9.6665ZM4.16732 14.3332C3.04332 14.3332 2.19998 14.2152 1.73665 13.6398C1.33398 13.1405 1.33398 12.4592 1.33398 11.5165L1.83398 11.4998H1.33398C1.33398 9.5865 1.45465 8.6665 4.16732 8.6665C6.87998 8.6665 7.00065 9.5865 7.00065 11.4998C7.00065 12.4585 7.00065 13.1405 6.59798 13.6398C6.13465 14.2152 5.29132 14.3332 4.16732 14.3332Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5013 9.6665C9.66797 9.6665 9.66797 9.80517 9.66797 11.4998V11.5165C9.66797 12.2545 9.66797 12.7878 9.84864 13.0132C10.0253 13.2318 10.55 13.3332 11.5013 13.3332C12.4526 13.3332 12.9773 13.2312 13.154 13.0125C13.3346 12.7878 13.3346 12.2545 13.3346 11.5158C13.3346 9.80517 13.3346 9.6665 11.5013 9.6665ZM11.5013 14.3332C10.3773 14.3332 9.53397 14.2152 9.07064 13.6398C8.66797 13.1405 8.66797 12.4592 8.66797 11.5165L9.16797 11.4998H8.66797C8.66797 9.5865 8.78864 8.6665 11.5013 8.6665C14.214 8.6665 14.3346 9.5865 14.3346 11.4998C14.3346 12.4585 14.3346 13.1405 13.932 13.6398C13.4686 14.2152 12.6253 14.3332 11.5013 14.3332Z"
        fill={fill}
      />
    </svg>
  );
};

export default categories;
