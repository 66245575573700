import React, { FC } from "react";
import styles from "./styles.module.scss";
import { Edit } from "~assets/svg";

type Props = {
  onClick?: () => void;
};

const EditButton: FC<Props> = ({ onClick }) => {
  return (
    <div onClick={onClick} className={styles.button}>
      <Edit />
      Edit
    </div>
  );
};

export default EditButton;
