import React, { useState } from "react";

import { PageLayout } from "~layouts";
import { ArrowLeft } from "~assets/svg";
import { useNavigate } from "react-router-dom";
import { Button, Input, UploadButton } from "~components";

import styles from "./styles.module.scss";

const CreateArchtype = ({}) => {
  const navigate = useNavigate();

  return (
    <PageLayout>
      <div className={styles.createArchtype}>
        <div className={styles.createArchtype__title}>Add new archtypes</div>
        <div
          className={styles.createArchtype__backArrow}
          onClick={() => navigate("/archtypes")}
        >
          <ArrowLeft />
        </div>

        <div className={styles.createArchtype__input}>
          <Input label="Name" placeholder="Enter your name" />
        </div>

        <div className={styles.createArchtype__bottom}>
          <div className={styles.createArchtype__bottomButton}>
            <Button text="Save" mode={"dark"} onClick={() => {}} />
          </div>
          <div className={styles.createArchtype__bottomButton}>
            <Button text="Cancel" mode={"light"} onClick={() => {}} />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default CreateArchtype;
