// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_button__C9oAb {\n  padding: 0 20px;\n  height: 39px;\n  border-radius: 8px;\n  border: 2px solid #D5D5D6;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  width: -moz-fit-content;\n  width: fit-content;\n  cursor: pointer;\n  font-weight: 500px;\n  font-size: 14px;\n  line-height: 21px;\n  color: #2B2D33;\n}\n.styles_button__C9oAb svg {\n  margin-right: 8px;\n}", "",{"version":3,"sources":["webpack://./src/components/UploadButton/styles.module.scss","webpack://./src/scss/vars.scss"],"names":[],"mappings":"AAGA;EACI,eAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,eAAA;EAEA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,cCNU;ADGd;AAKI;EACI,iBAAA;AAHR","sourcesContent":["@import '../../scss/vars.scss';\n\n\n.button{\n    padding: 0 20px;\n    height: 39px;\n    border-radius: 8px;\n    border: 2px solid $seatle30;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    width: fit-content;\n    cursor: pointer;\n\n    font-weight: 500px;\n    font-size: 14px;\n    line-height: 21px;\n    color: $primaryText;\n\n    svg{\n        margin-right: 8px;\n    }\n}","$black: #1D1D1D;\n$grayMedium: #AFAFAF;\n$gray: #E4E4E4;\n$grayLight: #F8F8F8;\n$grayInactive: #808191;\n$mainColor: #312E29;\n$secondary: #62B273;\n$secondaryInactive: #E9F9F1;\n$background: #F5ECE9;\n$red: #EB5757;\n$white: #FFFFFF;\n$grayText: #AAABAD;\n$primaryText: #2B2D33;\n$tableTH: #84818A;\n$seatle30: #D5D5D6;\n\n$inter: \"Inter\", sans-serif;\n$manrope: \"Manrope\", sans-serif;\n$poppins: \"Poppins\", sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "styles_button__C9oAb"
};
export default ___CSS_LOADER_EXPORT___;
