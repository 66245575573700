// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_prNavigation__5zTED {\n  display: flex;\n  height: 100vh;\n  max-width: 100vw;\n}\n.styles_prNavigation__content__WM4Ww {\n  width: 100%;\n  height: 100vh;\n}", "",{"version":3,"sources":["webpack://./src/navigation/PrivateNavigation/styles.module.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,aAAA;EACA,gBAAA;AADJ;AAGI;EACI,WAAA;EACA,aAAA;AADR","sourcesContent":["@import '../../scss/vars.scss';\n\n.prNavigation {\n    display: flex;\n    height: 100vh;\n    max-width: 100vw;\n\n    &__content{\n        width: 100%;\n        height: 100vh;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"prNavigation": "styles_prNavigation__5zTED",
	"prNavigation__content": "styles_prNavigation__content__WM4Ww"
};
export default ___CSS_LOADER_EXPORT___;
