import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";
import { iconsPng } from "~assets/png";

import { PageLayout } from "~layouts";
import {
  SearchInput,
  Table,
  DeleteButton,
  Pagination,
  Modal,
  ActionButton,
  Switch,
  ViewButton,
} from "~components";
import { axiosRequest } from "~api";
import { UserModel } from "~models";
import { USER_TABLE } from "~constants";
import { useNavigate } from "react-router-dom";

const PAGINATION_LIMIT = 20;
const UsersManagement = () => {
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [hidePaginationPage, setHidePaginationPage] = useState(false);
  const [data, setData] = useState<{ count: number; rows: UserModel[] }>({
    count: 0,
    rows: [],
  });

  const getUsers = async (newPage: number = 1) => {
    setShowLoader(true);
    const axios = await axiosRequest();
    axios
      .get(
        `users?page=${newPage}&limit=${PAGINATION_LIMIT}&sort=-createdAt&q=${searchValue}`
      )
      .then((resp) => {
        setHidePaginationPage(false);
        setShowLoader(false);
        setData(resp.data.data);
      })
      .catch((err) => console.log("getUsers ==> err", err.response));
  };

  const onPressBan = async (index: number, id: string) => {
    setData((prev) => {
      let state = structuredClone(prev);
      state.rows[index].isBaned = !state.rows[index].isBaned;
      return state;
    });
    const axios = await axiosRequest();
    axios.put(`users/${id}/ban`);
  };

  const onPressDelete = async (index: number) => {
    const userId = data.rows[index].id;
    setData((prev) => {
      let state = structuredClone(prev);
      state.rows.splice(index, 1);
      state.count = state.count - 1;
      return state;
    });
    const axios = await axiosRequest();
    axios.delete(`users/${userId}`);
  };

  const changeAdminRole = async (index: number, id: string) => {
    const newRole = data.rows[index].role === "user" ? "admin" : "user";
    setData((prev) => {
      let state = structuredClone(prev);
      state.rows[index].role = newRole;
      return state;
    });

    const axios = await axiosRequest();
    axios.put(`users/${id}`, { role: newRole });
  };

  const returnOptionalContact = (item: UserModel) => {
    // {item.authType === "phone" ? item.phone : item.email}

    if (item.authType === "phone" && !!item.email) {
      return item.email;
    } else if (item.authType !== "phone" && !!item.phone) {
      return item.phone;
    } else return "";
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setHidePaginationPage(true);
      getUsers();
    }, 650);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return (
    <PageLayout>
      <div className={styles.usersManagement}>
        <div className={styles.usersManagement__header}>
          <div className={styles.usersManagement__headerTitle}>
            Mobile app users
          </div>
          <div className={styles.usersManagement__headerInput}>
            <SearchInput
              value={searchValue}
              onChange={setSearchValue}
              placeholder={"Search for a user"}
            />
          </div>
        </div>

        <Table
          loading={showLoader}
          headers={USER_TABLE}
          items={data.rows}
          renderTableItem={(item: UserModel, index: number) => {
            console.log(item);
            return (
              <tr>
                <td>
                  {!!item?.firstName && !!item?.lastName ? (
                    <>
                      <div className={styles.nameBlock}>
                        {!!item?.avatar ? (
                          <img
                            src={item.avatar}
                            className={styles.nameBlock__avatar}
                          />
                        ) : (
                          <div className={styles.nameBlock__emptyAvatar}>
                            {!!item?.firstName[0] ? item?.firstName[0] : ""}
                            {!!item?.lastName[0] ? item?.lastName[0] : ""}
                          </div>
                        )}
                        {item.firstName} {item.lastName}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={styles.nameBlock}>
                        <div className={styles.nameBlock__emptyAvatar}>NR</div>
                        Not registered
                      </div>
                    </>
                  )}
                </td>
                <td>
                  <div className={styles.email}>
                    {item.authType === "phone" ? item.phone : item.email}
                    {!!returnOptionalContact(item) && (
                      <div>
                        <span>Optional:</span> {returnOptionalContact(item)}
                      </div>
                    )}
                  </div>
                </td>
                <td>
                  <div className={styles.status}>
                    Followers: {item.followers}
                  </div>
                  <div className={styles.status}>
                    Following: {item.followings}
                  </div>
                  <div className={styles.status}>
                    Recommended place: {item.recommended}
                  </div>
                </td>
                <td>
                  <Switch
                    value={item.role === "admin"}
                    onClick={() => changeAdminRole(index, item.id)}
                  />
                </td>
                <td>
                  <div className={styles.actionsBlock}>
                    <ViewButton
                      handleClick={() => {
                        navigate(`${item.id}`, { state: item });
                      }}
                    />

                    <DeleteButton onClick={() => onPressDelete(index)} />
                    <ActionButton
                      color={item.isBaned ? "green" : "red"}
                      text={item.isBaned ? "Unban user" : "Ban user"}
                      onClick={() => onPressBan(index, item.id)}
                      modalText={`Are you shure you want to ${
                        item.isBaned ? "unban" : "ban"
                      } this user?`}
                    />
                  </div>
                </td>
              </tr>
            );
          }}
        />

        <Pagination
          currentPage={page}
          dataLength={data.count}
          paginationLimit={PAGINATION_LIMIT}
          setPage={setPage}
          loadData={getUsers}
          hidePaginationPage={hidePaginationPage}
        />
      </div>
    </PageLayout>
  );
};

export default UsersManagement;
