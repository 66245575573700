import React, { FC } from "react";

import styles from "./styles.module.scss";

type Props = {
  children: JSX.Element;
};

const PageLayout: FC<Props> = ({ children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default PageLayout;
