import React from "react";

const comment = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1V1.85714H13V1H1ZM1 5.56857H9.57143V4.71143H1V5.56857ZM1 9.28857H13V8.43143H1V9.28857ZM1 13H9.57143V12.1429H1V13Z"
        fill="black"
        stroke="black"
        stroke-width="0.4"
      />
    </svg>
  );
};

export default comment;
