import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";

import { PageLayout } from "~layouts";
import {
  Button,
  Table,
  EditButton,
  DeleteButton,
  ActionButton,
} from "~components";
import { PlusSquare } from "~assets/svg";
import { useNavigate } from "react-router-dom";
import { CATEGORIES_TABLE } from "~constants";
import { axiosRequest } from "~api";
import { UserModel } from "~models";
import { CategoryModel } from "models/CategoryModel";

const Categories = () => {
  const navigate = useNavigate();
  const editCategory = (category: CategoryModel) => {
    navigate("/categories/edit", { state: { category } });
  };

  const editTags = (category: CategoryModel) => {
    navigate("/categories/tags", { state: { category } });
  };

  const [showLoader, setShowLoader] = useState(false);

  const [data, setData] = useState<{ count: number; rows: UserModel[] }>({
    count: 0,
    rows: [],
  });

  console.log("data", data);

  const getCategories = async () => {
    setShowLoader(true);
    const axios = await axiosRequest();
    axios.get("categories").then((resp) => {
      setShowLoader(false);
      setData(resp.data.data);
    });
  };
  useEffect(() => {
    getCategories();
  }, []);

  return (
    <PageLayout>
      <div className={styles.categoriesBlock}>
        <div className={styles.categoriesBlock__header}>
          <div className={styles.categoriesBlock__headerTitle}>Categories</div>

          {/* <div className={styles.categoriesBlock__headerButton}>
            <Button
              text="Add categories"
              mode={"dark"}
              onClick={addCategories}
              svg={<PlusSquare />}
            />
          </div> */}
        </div>
        <Table
          loading={showLoader}
          headers={CATEGORIES_TABLE}
          items={data.rows}
          renderTableItem={(item: any) => {
            return (
              <tr>
                <td>
                  <img src={item.icon} className={styles.image} />
                </td>
                <td>
                  <div className={styles.name}>{item.title}</div>
                </td>
                <td>
                  <div className={styles.actionsBlock}>
                    <EditButton onClick={() => editCategory(item)} />
                    <div className={styles.actionsBlock__deleteButton}>
                      <ActionButton
                        color={"green"}
                        text={"Show tags"}
                        onClick={() => editTags(item)}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            );
          }}
        />
      </div>
    </PageLayout>
  );
};

export default Categories;
