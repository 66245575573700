import React, { FC } from "react";

import { Button } from "~components";

import styles from "./styles.module.scss";

type Props = {
  children: JSX.Element;
  onClose?: () => void;
  onClick?: () => void;
  buttonText?: string;
  hideButtons?: boolean;
  disableButton?: boolean;
  showLoader?: boolean;
};

const Modal: FC<Props> = ({
  children,
  onClose,
  onClick,
  buttonText = "",
  hideButtons = false,
  disableButton,
  showLoader,
}) => {
  return (
    <div className={styles.modal__bg}>
      <div className={styles.modal}>
        {children}
        {!hideButtons && (
          <div className={styles.modal__footer}>
            <div className={styles.modal__footerButton}>
              <Button
                mode={"light"}
                text={"Cancel"}
                onClick={!!onClose ? onClose : () => {}}
              />
            </div>

            <div className={styles.modal__footerButton}>
              <Button
                loading={showLoader}
                disable={disableButton}
                mode={"dark"}
                text={buttonText}
                onClick={!!onClick ? onClick : () => {}}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
