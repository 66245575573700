import React, { useEffect, useState } from "react";

import { PageLayout } from "~layouts";
import { ArrowLeft, PlusIcon } from "~assets/svg";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Dropdown, Input, Modal, UploadButton } from "~components";
import { DescriptionRow } from "react-native-google-places-autocomplete";

import { Client } from "@googlemaps/google-maps-services-js";

import styles from "./styles.module.scss";
import { axiosRequest } from "~api";
import { UserModel } from "~models";
import { ListModel } from "models/ListModel";
import { getToken } from "~utils";
import axios from "axios";
import { BASE_URL } from "~constants";

const client = new Client({});

const ImportPlaces = ({}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [admins, setAdmins] = useState<UserModel[]>([]);
  const [lists, setLists] = useState<ListModel[]>([]);

  const [admin, setAdmin] = useState<UserModel | null>(null);
  const [list, setList] = useState<ListModel | null>(null);

  const [showAdminsLoader, setShowAdminsLoader] = useState(false);
  const [showListsLoader, setShowListsLoader] = useState(false);

  const [listName, setListName] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [modalError, setModalError] = useState("");
  const [showModalLoader, setShowModalLoader] = useState(false);

  const [file, setFile] = useState<any | null>(null);

  const [showButtonLoader, setShowButtonLoader] = useState(false);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const getAdmins = async () => {
    setShowAdminsLoader(true);
    const axios = await axiosRequest();
    axios.get("users?limit=100&role=admin&page=1").then((resp) => {
      setShowAdminsLoader(false);
      setAdmins(resp.data.data.rows);
    });
  };

  const getAdminLists = async (adminId: string) => {
    setShowListsLoader(true);
    const axios = await axiosRequest();
    axios.get(`lists/custom/user/${adminId}`).then((resp) => {
      setLists(resp.data.data);
      setShowListsLoader(false);
    });
  };

  const createList = async () => {
    setShowModalLoader(true);
    const axios = await axiosRequest();
    axios
      .post(`lists/user/${admin?.id}`, { title: listName })
      .then((resp) => {
        setShowModalLoader(false);
        setLists((prev) => {
          let state = [...prev];
          state.unshift(resp.data.data);
          return state;
        });
        setList(resp.data.data);
        setShowModal(false);
        setListName("");
      })
      .catch((err) => {
        setShowModalLoader(false);
        setModalError(err.response.data.message);
        console.log("createList --> err", err.response);
      });
  };

  const savePlace = async () => {
    setShowButtonLoader(true);
    const formData = new FormData();

    const token = await getToken();
    formData.append("file", file);
    formData.append("user", !!admin?.id ? admin?.id : "");
    formData.append("list", !!list?.id ? list?.id : "");

    await axios({
      method: "POST",
      url: `${BASE_URL}/places/batch`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        setShowButtonLoader(false);
        setShowSuccessMessage(true);
        setAdmin(null);
        setList(null);
        setFile(null);
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 2000);
      })
      .catch((err) => {
        setShowButtonLoader(false);
        setShowErrorMessage(true);
        setTimeout(() => {
          setShowErrorMessage(false);
        }, 2000);
      });
  };

  const onPressCancel = () => {
    setAdmin(null);
    setList(null);
    setFile(null);
  };

  const handleAdminDropdown = (admin: UserModel) => {
    setAdmin(admin);
    setList(null);
    getAdminLists(admin.id);
  };

  useEffect(() => {
    getAdmins();
  }, []);

  const isDisable = !admin || !list || !file;

  return (
    <PageLayout>
      <div className={styles.importPlaces}>
        <div className={styles.importPlaces__title}>
          Import places
          {showSuccessMessage && <span> •{"   "}file sent successfully </span>}
          {showErrorMessage && (
            <span style={{ color: "red" }}> •{"   "}incorrect data</span>
          )}
        </div>
        <div
          className={styles.importPlaces__backArrow}
          onClick={() => navigate("/places")}
        >
          <ArrowLeft />
        </div>

        <div className={styles.importPlaces__input}>
          <Dropdown
            value={!!admin ? `${admin?.firstName} ${admin?.lastName}` : ""}
            label="Select admin"
            placeholder="Select admin"
            data={admins.map((item) => `${item.firstName} ${item.lastName}`)}
            loading={showAdminsLoader}
            onSave={(value, index) => handleAdminDropdown(admins[index])}
          />
        </div>

        <div className={styles.importPlaces__listsBlock}>
          <div className={styles.importPlaces__input}>
            <Dropdown
              isDisable={!admin}
              value={!!list ? list.title : ""}
              label="Select list"
              placeholder="Select list"
              data={lists.map((item) => item.title)}
              loading={showListsLoader}
              onSave={(value, index) => setList(lists[index])}
            />
          </div>

          {!!admin && (
            <div
              onClick={() => setShowModal(true)}
              className={styles.importPlaces__listsBlockButton}
            >
              <PlusIcon />
            </div>
          )}
        </div>

        <div className={styles.importPlaces__upload}>
          <UploadButton
            type={"file"}
            text={"Choose File"}
            saveFile={(file) => {
              setFile(file);
            }}
          />
          {!!file && !!file?.name && <span>{file.name}</span>}
        </div>

        <div className={styles.importPlaces__download}>
          <a
            href="https://camber-bucket.s3.us-east-2.amazonaws.com/example.csv"
            download={"example.csv"}
          >
            Download sample file
          </a>
        </div>

        <div className={styles.importPlaces__bottom}>
          <div className={styles.importPlaces__bottomButton}>
            <Button
              loading={showButtonLoader}
              disable={isDisable}
              text={!!location.state?.category ? "Update" : "Save"}
              mode={"dark"}
              onClick={savePlace}
            />
          </div>
          <div className={styles.importPlaces__bottomButton}>
            <Button text="Cancel" mode={"light"} onClick={onPressCancel} />
          </div>
        </div>

        {showModal && (
          <Modal
            buttonText={"Create list"}
            disableButton={!listName || !!modalError}
            onClick={createList}
            showLoader={showModalLoader}
            onClose={() => {
              setShowModal(false);
              setListName("");
              setModalError("");
            }}
          >
            <div>
              <div className={styles.importPlaces__modalTitle}>
                Enter list name
              </div>
              <Input
                label=""
                value={listName}
                onChange={(val) => {
                  setListName(val);
                  !!modalError && setModalError("");
                }}
                placeholder={"List name"}
                error={modalError}
              />
            </div>
          </Modal>
        )}
      </div>
    </PageLayout>
  );
};

export default ImportPlaces;
