import React from "react";

const close = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 1.53003L16.3293 0.859375L9 8.17914L1.67066 0.859375L1 1.53003L8.31976 8.85938L1 16.1887L1.67066 16.8594L9 9.53961L16.3293 16.8594L17 16.1887L9.68024 8.85938L17 1.53003Z"
      fill="black"
      stroke="black"
    />
  </svg>
);
export default close;
