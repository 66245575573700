import React from "react";

import styles from "./styles.module.scss";
import { PaginationArrow } from "~assets/svg";
import { FC } from "react";

type Props = {
  dataLength?: number;
  currentPage?: number;
  paginationLimit?: number;
  loadData?: (page: number) => void;
  setPage?: (page: number) => void;
  hidePaginationPage?: boolean;
};

const Pagination: FC<Props> = ({
  dataLength,
  currentPage,
  paginationLimit,
  loadData,
  setPage,
  hidePaginationPage,
}) => {
  if (
    typeof dataLength !== "number" ||
    typeof currentPage !== "number" ||
    typeof paginationLimit !== "number"
  )
    return null;
  const maxPages = Math.ceil(dataLength / paginationLimit);

  const disableLoadPrevPage = dataLength === 0 || currentPage === 1;
  const disableLoadNextPage = maxPages === currentPage;

  const loadPrevPage = () => {
    if (currentPage > 1 && !!loadData && !!setPage) {
      setPage(currentPage - 1);
      loadData(currentPage - 1);
    }
  };

  const loadNextPage = () => {
    if (maxPages > currentPage && !!loadData && !!setPage) {
      setPage(currentPage + 1);
      loadData(currentPage + 1);
    }
  };

  return (
    <div className={styles.paginationBlock}>
      <div onClick={loadPrevPage}>
        <PaginationArrow fill={disableLoadPrevPage ? "#CCCDCE" : "#404247"} />
      </div>

      <div className={styles.paginationBlock__activePage}>
        {dataLength === 0 || hidePaginationPage ? 0 : currentPage}
      </div>
      <div className={styles.paginationBlock__paragraph}>of</div>
      <div className={styles.paginationBlock__disactivePage}>
        {dataLength === 0 || hidePaginationPage ? 0 : maxPages}
      </div>
      <div
        onClick={loadNextPage}
        className={styles.paginationBlock__rotateArrow}
      >
        <PaginationArrow
          fill={
            disableLoadNextPage || dataLength === 0 || hidePaginationPage
              ? "#CCCDCE"
              : "#404247"
          }
        />
      </div>
    </div>
  );
};

export default Pagination;
