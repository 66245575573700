import React from "react";

type Props = {
  fill: string;
};

const deleteIcon = ({ fill }: Props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.12269 11.0001C5.44519 11.0001 4.78469 10.9926 4.13119 10.9791C3.29519 10.9626 2.71669 10.4206 2.62219 9.56457C2.46469 8.14457 2.19519 4.79757 2.19269 4.76407C2.17569 4.55757 2.32969 4.37657 2.53619 4.36007C2.73969 4.35457 2.92369 4.49757 2.94019 4.70357C2.94269 4.73757 3.21169 8.07307 3.36769 9.48207C3.42119 9.96857 3.68369 10.2196 4.14669 10.2291C5.39669 10.2556 6.67219 10.2571 8.04719 10.2321C8.53919 10.2226 8.80519 9.97657 8.86019 9.47857C9.01519 8.08157 9.28519 4.73757 9.28819 4.70357C9.30469 4.49757 9.48719 4.35357 9.69169 4.36007C9.89819 4.37707 10.0522 4.55757 10.0357 4.76407C10.0327 4.79807 9.76169 8.15357 9.60569 9.56107C9.50869 10.4346 8.93169 10.9661 8.06069 10.9821C7.39419 10.9936 6.75119 11.0001 6.12269 11.0001Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.354 3.49463H1.875C1.668 3.49463 1.5 3.32663 1.5 3.11963C1.5 2.91263 1.668 2.74463 1.875 2.74463H10.354C10.561 2.74463 10.729 2.91263 10.729 3.11963C10.729 3.32663 10.561 3.49463 10.354 3.49463Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.71931 3.49474C8.15031 3.49474 7.65631 3.08924 7.54431 2.53124L7.42281 1.92324C7.39731 1.83074 7.29181 1.75024 7.17181 1.75024H5.05531C4.93531 1.75024 4.82981 1.83074 4.79931 1.94624L4.68281 2.53124C4.57131 3.08924 4.07681 3.49474 3.50781 3.49474C3.30081 3.49474 3.13281 3.32674 3.13281 3.11974C3.13281 2.91274 3.30081 2.74474 3.50781 2.74474C3.72081 2.74474 3.90581 2.59274 3.94781 2.38374L4.06931 1.77574C4.19281 1.30974 4.59631 1.00024 5.05531 1.00024H7.17181C7.63081 1.00024 8.03431 1.30974 8.15281 1.75324L8.27981 2.38374C8.32131 2.59274 8.50631 2.74474 8.71931 2.74474C8.92631 2.74474 9.09431 2.91274 9.09431 3.11974C9.09431 3.32674 8.92631 3.49474 8.71931 3.49474Z"
        fill={fill}
      />
    </svg>
  );
};

export default deleteIcon;
