import React from "react";

import { LeftPanel } from "~blocks";
import styles from "./styles.module.scss";
import { Outlet } from "react-router-dom";

const Dashboard = () => {
  return (
    <div className={styles.prNavigation}>
      <LeftPanel />
      <div className={styles.prNavigation__content}>
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
