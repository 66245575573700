import { UserModel } from "./UserModel";

export enum ReportType {
  user = "user",
  activity = "activity",
  general = "general",
  feedback = "feedback",
}

export type ReportModel = {
  id: string;
  reason?: string;
  status: string;
  reported: UserModel;
  reporter: UserModel;
  reportedComment: {
    attachments: string[];
    comment: string;
    createdAt: string;
    text: string;
    id: string;
    place: string;
    activity: {
      id: string;
      place: string;
    };
  };
  reportedCommentType: "SubComment" | "PlaceActivity";
};
