// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_modal__5D1PS {\n  width: 640px;\n  min-height: 240px;\n  border-radius: 24px;\n  background-color: white;\n  margin: 0 auto;\n  margin-top: 160px;\n  padding: 24px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n.styles_modal__bg__E0LnB {\n  position: absolute;\n  width: 100vw;\n  height: 100vh;\n  top: 0;\n  left: 0;\n  background-color: rgba(37, 37, 37, 0.7);\n  z-index: 20000;\n}\n.styles_modal__footer__FSLNo {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n.styles_modal__footerButton__CRo4C {\n  width: 157px;\n}", "",{"version":3,"sources":["webpack://./src/components/Modal/styles.module.scss"],"names":[],"mappings":"AAOA;EAEI,YAAA;EACA,iBAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;EACA,iBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;AAPJ;AASI;EACI,kBAAA;EACA,YAAA;EACA,aAAA;EACA,MAAA;EACA,OAAA;EACA,uCAAA;EACA,cAAA;AAPR;AAUI;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;AARR;AAUQ;EACI,YAAA;AARZ","sourcesContent":["@import '../../scss/vars.scss';\n\n\n\n\n\n\n.modal {\n\n    width: 640px;\n    min-height: 240px;\n    border-radius: 24px;\n    background-color: white;\n    margin: 0 auto;\n    margin-top: 160px;\n    padding: 24px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n\n    &__bg{\n        position: absolute;\n        width: 100vw;\n        height: 100vh;\n        top: 0;\n        left: 0;\n        background-color: rgba(37, 37, 37, 0.7);\n        z-index: 20000;\n    }\n\n    &__footer{\n        display: flex;\n        flex-direction: row;\n        justify-content: space-between;\n\n        &Button{\n            width: 157px;\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "styles_modal__5D1PS",
	"modal__bg": "styles_modal__bg__E0LnB",
	"modal__footer": "styles_modal__footer__FSLNo",
	"modal__footerButton": "styles_modal__footerButton__CRo4C"
};
export default ___CSS_LOADER_EXPORT___;
