import logo from "./logo.png";

import user1 from "./defaultData/user1.png";
import user2 from "./defaultData/user2.png";
import user3 from "./defaultData/user3.png";
import user4 from "./defaultData/user4.png";
import user5 from "./defaultData/user5.png";
import user6 from "./defaultData/user6.png";
import user7 from "./defaultData/user7.png";

import coffe from "./defaultData/coffe.png";
import desert from "./defaultData/desert.png";
import drinks from "./defaultData/drinks.png";
import food from "./defaultData/food.png";
import hotels from "./defaultData/hotels.png";
import museum from "./defaultData/museum.png";
import outdors from "./defaultData/outdors.png";
import sights from "./defaultData/sights.png";
import marker from "./marker.png";

import minuse from "./minuse.png";
import plus from "./plus.png";

export const iconsPng = {
  logo,
  user1,
  user2,
  user3,
  user4,
  user5,
  user6,
  user7,
  coffe,
  desert,
  drinks,
  food,
  hotels,
  museum,
  outdors,
  sights,
  marker,
  minuse,
  plus,
};
