import React, { useEffect, useRef, useState } from "react";

import styles from "./styles.module.scss";

import { PageLayout } from "~layouts";
import {
  Button,
  SearchInput,
  Table,
  EditButton,
  DeleteButton,
  Pagination,
  Modal,
  UploadButton,
} from "~components";
import { PlusSquare } from "~assets/svg";
import { useNavigate } from "react-router-dom";
import { BASE_URL, PLACES_TABLE } from "~constants";
import { PlaceModel } from "~models";
import { axiosRequest } from "~api";
import axios from "axios";
import { getToken } from "~utils";

const PAGINATION_LIMIT = 20;
const PlacesManagement = () => {
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [loader, setLoader] = useState(true);
  const [hidePaginationPage, setHidePaginationPage] = useState(false);
  const [file, setFile] = useState<null | any>(null);
  const [showImportLoader, setShowImportLoader] = useState(false);
  const [data, setData] = useState<{ count: number; rows: PlaceModel[] }>({
    count: 0,
    rows: [],
  });
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [page, setPage] = useState(1);
  const onPressDelete = async (index: number, id: string) => {
    setData((prev) => {
      let state = structuredClone(prev);
      state.count = state.count - 1;
      state.rows.splice(index, 1);
      return state;
    });

    const axios = await axiosRequest();
    axios.delete(`places/${id}`);
  };

  const getPlaces = async (newPage: number = 1) => {
    setLoader(true);
    const axios = await axiosRequest();
    axios
      .get(`places?page=${newPage}&limit=${PAGINATION_LIMIT}&q=${searchValue}`)
      .then((resp) => {
        setPage(newPage);
        setLoader(false);
        setHidePaginationPage(false);
        setData({ count: resp.data.data.count, rows: resp.data.data.rows });
      });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setHidePaginationPage(true);
      getPlaces();
    }, 650);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  const importFile = async () => {
    setShowImportLoader(true);
    const formData = new FormData();

    const token = await getToken();
    formData.append("file", file);

    await axios({
      method: "POST",
      url: `${BASE_URL}/places/batch`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        setFile(null);
        setShowImportLoader(false);
        setShowSuccessMessage(true);

        getPlaces();
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 2000);
      })
      .catch((err) => {
        setShowErrorMessage(true);
        setTimeout(() => {
          setShowErrorMessage(false);
        }, 2000);
      });
  };

  return (
    <PageLayout>
      <div className={styles.placesManagement}>
        <div className={styles.placesManagement__header}>
          <div className={styles.placesManagement__headerTitle}>Places</div>

          <div className={styles.placesManagement__headerRight}>
            <div className={styles.placesManagement__headerButton}>
              <Button
                text="Import places"
                mode={"dark"}
                onClick={() => navigate("/places/import")}
                svg={<PlusSquare />}
              />
            </div>

            <div className={styles.placesManagement__headerButton}>
              <Button
                text="Fetch places"
                mode={"dark"}
                onClick={() => navigate("/places/fetch")}
                svg={<PlusSquare />}
              />
            </div>

            <div className={styles.placesManagement__headerButton}>
              <Button
                text="Add places"
                mode={"dark"}
                onClick={() => navigate("/places/create")}
                svg={<PlusSquare />}
              />
            </div>

            <div className={styles.placesManagement__headerInput}>
              <SearchInput
                value={searchValue}
                onChange={setSearchValue}
                placeholder="Search"
              />
            </div>
          </div>
        </div>

        <Table
          loading={loader}
          headers={PLACES_TABLE}
          items={data.rows}
          renderTableItem={(item: PlaceModel, index: number) => {
            return (
              <tr>
                <td>
                  <div className={styles.name}>{item.title}</div>
                </td>
                <td>
                  <div className={styles.paragraph}>{item.category.title}</div>
                </td>
                <td>
                  <div className={styles.paragraph}>{item.address}</div>
                </td>

                <td>
                  <div className={styles.paragraph}>{item.recommended}</div>
                </td>
                <td>
                  <div className={styles.paragraph}>{item.wantToGo}</div>
                </td>
                <td>
                  <div className={styles.actionsBlock}>
                    <EditButton
                      onClick={() =>
                        navigate("/places/create", { state: item })
                      }
                    />
                    <DeleteButton
                      color={"red"}
                      onClick={() => onPressDelete(index, item.id)}
                    />
                  </div>
                </td>
              </tr>
            );
          }}
        />
        <Pagination
          dataLength={data.count}
          paginationLimit={PAGINATION_LIMIT}
          currentPage={page}
          setPage={setPage}
          loadData={getPlaces}
          hidePaginationPage={hidePaginationPage}
        />

        {showModal && (
          <Modal
            buttonText={"Import"}
            disableButton={!file}
            onClose={() => {
              setShowModal(false);
              setFile(null);
            }}
            showLoader={showImportLoader}
            onClick={() => importFile()}
          >
            <div className={styles.placesManagement__modal}>
              <div className={styles.placesManagement__modalTitle}>
                Import Place
                {showSuccessMessage && (
                  <span> •{"   "}file sent successfully</span>
                )}
                {showErrorMessage && (
                  <span style={{ color: "red" }}> •{"   "}incorrect data</span>
                )}
              </div>
              <div className={styles.placesManagement__modalButton}>
                <UploadButton
                  type={"file"}
                  text={"Choose File"}
                  saveFile={(file) => {
                    setFile(file);
                  }}
                />
                {!!file && !!file?.name && <span>{file.name}</span>}
              </div>

              <div className={styles.placesManagement__modalDownload}>
                <a
                  href="https://camber-bucket.s3.us-east-2.amazonaws.com/example.csv"
                  download={"example.csv"}
                >
                  Download sample file
                </a>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </PageLayout>
  );
};

export default PlacesManagement;
