import { PageLayout } from "~layouts";
import styles from "./userDetailStyles.module.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ArrowLeft } from "~assets/svg";
import { Button, Input, Switch, ViewButton } from "~components";
import { useEffect, useState } from "react";
import { axiosRequest } from "~api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UserDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [initialState, setInitialState] = useState({
    firstName: location.state.firstName,
    lastName: location.state.lastName,
    email: location.state.email,
    phone: location.state.phone,
    role: location.state.role,
  });

  const [showMoreTags, setShowMoreTags] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({ ...initialState });
  const initialNumberOfItemsToShow = 10; // Adjust based on your preference
  const displayedTags = showMoreTags
    ? location.state.usedTags
    : location.state.usedTags.slice(0, initialNumberOfItemsToShow);

  const toggleShowMore = () => {
    setShowMoreTags(!showMoreTags);
  };

  const handleUpdate = async (id: string) => {
    setLoading(true);
    try {
      console.log("userData--->", userData);
      const axios = await axiosRequest();
      let response = await axios.put(`users/${id}`, { ...userData });
      console.log("response--->", response.data);
      if (response.data.data) {
        setLoading(false);
        setInitialState({
          firstName: response.data.data.firstName,
          lastName: response.data.data.lastName,
          email: response.data.data.email,
          phone: response.data.data.phone,
          role: response.data.data.role,
        });
        toast("✔️ Updated successfully", {
          hideProgressBar: true,
          style: { backgroundColor: "#E4FCE4", color: "#000" },
        });
      } else {
        console.log("response.data", response);
        setLoading(false);
      }
    } catch (err: any) {
      console.log("err", err.response.data.message);
      toast(`❌ ${err.response.data.message}`, {
        hideProgressBar: true,
        style: { backgroundColor: "#fce4e4", color: "#000" },
      });
      setLoading(false);
    }
  };

  //   const getUserById = async () => {
  //     const axios = await axiosRequest();
  //     let response = await axios.get(`users/${params.id}`);
  //     setUserData(response.data.data);
  //     console.log("response", response);
  //   };
  //   useEffect(() => {
  //     getUserById();
  //   }, []);

  return (
    <PageLayout>
      <div className={styles.userDetail}>
        <ToastContainer />
        <div
          className={styles.userDetail__backArrow}
          onClick={() => navigate("/users")}
        >
          <ArrowLeft />
        </div>
        <div className={styles.userDetail__title}>User Detail</div>

        <div className={styles.userDetail__avatar}>
          {location.state.avatar && location.state.avatar !== "" ? (
            <img src={location.state.avatar} />
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                border: "2px solid #000",
                height: 75,
                width: 75,
                borderRadius: 100,
                marginBottom: 10,
              }}
            >
              <p style={{ fontSize: 28, fontWeight: 600, margin: 0 }}>
                {location.state.firstName.split("")[0].toUpperCase()}
              </p>
              <p style={{ fontSize: 28, fontWeight: 600, margin: 0 }}>
                {location.state.lastName.split("")[0].toUpperCase()}
              </p>
            </div>
          )}

          <div className={styles.status}>
            Profile Type:{" "}
            {location.state.isPrivate ? "Private Profile" : "Public Profile"}
          </div>
          <div className={styles.status}>
            Followers: {location.state.followers}
          </div>
          <div className={styles.status}>
            Following: {location.state.followings}
          </div>
          <div className={styles.status}>
            Recommended place: {location.state.recommended}
          </div>
          <div className={styles.status}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p style={{ marginBottom: 0, marginRight: 6, marginTop: 0 }}>
                Place list: {location.state.lists}
              </p>
              {location.state.lists > 0 ? (
                <ViewButton
                  handleClick={() => {
                    navigate(`list`, {
                      state: { ...userData, userId: location.state.id },
                    });
                  }}
                />
              ) : null}
            </div>
          </div>

          <p>{location.state.bio}</p>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 12,
              marginTop: 12,
            }}
          >
            <p style={{ margin: 0, paddingRight: 16 }}>Is Admin: </p>
            <Switch
              value={userData.role === "admin"}
              onClick={() =>
                setUserData({
                  ...userData,
                  role: userData.role === "user" ? "admin" : "user",
                })
              }
            />
          </div>

          <div
            style={{
              flexDirection: "row",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {displayedTags.map((item: string, index: number) => {
              return (
                <p className={styles.tags} key={index}>
                  {item}
                </p>
              );
            })}
            <p
              style={{
                cursor: "pointer",
                fontSize: 14,
                display: location.state.usedTags.length > 0 ? "flex" : "none",
              }}
              onClick={() => {
                toggleShowMore();
              }}
            >
              Show {showMoreTags ? "less..." : "more..."}
            </p>
          </div>
        </div>

        <div className={styles.userDetail__basicDetail}>
          <div style={{ width: "48%" }}>
            <Input
              value={userData.firstName}
              onChange={(e) => {
                setUserData({
                  ...userData,
                  firstName: e,
                });
              }}
              //   disabled={true}
              label="First name"
              placeholder="First Name"
            />
          </div>

          <div style={{ width: "48%" }}>
            <Input
              value={userData.lastName}
              onChange={(e) => {
                setUserData({
                  ...userData,
                  lastName: e,
                });
              }}
              //   disabled={true}
              label="Last name"
              placeholder="Last Name"
            />
          </div>
        </div>

        <div className={styles.userDetail__basicDetail}>
          {location.state.email && location.state.email !== "" && (
            <div style={{ width: "48%" }}>
              <Input
                value={userData.email}
                onChange={(e) => {
                  setUserData({
                    ...userData,
                    email: e,
                  });
                }}
                // disabled={true}
                label="Email"
                placeholder="Email"
              />
            </div>
          )}

          {location.state.phone && location.state.phone !== "" && (
            <div style={{ width: "48%" }}>
              <Input
                value={userData.phone}
                onChange={(e) => {
                  setUserData({
                    ...userData,
                    phone: e,
                  });
                }}
                // disabled={true}
                label="Phone"
                placeholder="Phone"
              />
            </div>
          )}
        </div>

        <div className={styles.userDetail__bottom}>
          <div className={styles.userDetail__bottomButton}>
            <Button
              disable={
                JSON.stringify(initialState) === JSON.stringify(userData)
                  ? true
                  : false
              }
              text={"Update"}
              mode={"dark"}
              loading={loading}
              onClick={() => {
                handleUpdate(location.state.id);
              }}
            />
          </div>
          <div className={styles.userDetail__bottomButton}>
            <Button
              text="Cancel"
              mode={"light"}
              onClick={() => {
                setUserData(initialState);
              }}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
export default UserDetail;
