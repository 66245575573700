import React, { useState } from "react";

import styles from "./styles.module.scss";

import { PageLayout } from "~layouts";
import { Table, Pagination, ActionButton, Modal } from "~components";
import clsx from "clsx";
import { ReportModel, ReportType } from "~models";
import { useEffect } from "react";
import { axiosRequest } from "~api";
import { REPORT_TABLE_COLUMNS } from "~constants";
import CommentModal from "./CommentModal";

const PAGINATION_LIMIT = 20;
const CommentReports = () => {
  const [reportState, setReportState] = useState(0);
  const [choosedReportIndex, setChoosedReportIndex] = useState<null | number>(
    null
  );
  const [data, setData] = useState<{ count: number; rows: ReportModel[] }>({
    count: 0,
    rows: [],
  });
  const [page, setPage] = useState(1);
  const [showLoader, setShowLoader] = useState(false);

  const getReports = async (newPage: number = 1) => {
    setShowLoader(true);
    const axios = await axiosRequest();
    axios
      .get(
        `reports?type=${ReportType.activity}&status=${
          reportState === 0 ? "active" : "archived"
        }&page=${newPage}&limit=${PAGINATION_LIMIT}&sort=-createdAt`
      )
      .then((resp) => {
        setPage(newPage);
        setData(resp.data.data);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const onPressBan = async (index: number, userId: string) => {
    setData((prev) => {
      let state = structuredClone(prev);
      const newBanStatus = !state.rows[index].reported.isBaned;
      state.rows.forEach((item: ReportModel, index: number) => {
        if (item.reported?.id === userId)
          state.rows[index].reported.isBaned = newBanStatus;
      });
      return state;
    });
    const axios = await axiosRequest();
    axios.put(`users/${userId}/ban`);
  };

  const onPressDeleteUser = async (index: number, id: string) => {
    setData((prev) => {
      let state = structuredClone(prev);
      state.rows.splice(index, 1);
      state.count = state.count - 1;
      return state;
    });

    const axios = await axiosRequest();
    axios.delete(`users/${id}`);
  };

  const onPressArchive = async (index: number, id: string) => {
    const rows = [...data.rows];
    rows.splice(index, 1);

    setData((prev) => {
      let state = structuredClone(prev);
      state.rows = rows;
      state.count = state.count - 1;
      return state;
    });
    const axios = await axiosRequest();
    axios.put(`reports/${id}`);
  };

  const onPressDeleteComment = async (index: number, commentId: string) => {
    const item = data.rows[index];
    setData((prev) => {
      let state = structuredClone(prev);
      console.log("state before", state);

      state.rows.forEach((item: ReportModel, index: number) => {
        if (item?.reportedComment?.id === commentId)
          state.rows[index].reportedComment = null;
      });
      return state;
    });

    const axios = await axiosRequest();

    if (item.reportedCommentType === "PlaceActivity") {
      axios.delete(
        `places/${item.reportedComment.place}/activities/${item.reportedComment.id}`
      );
    } else {
      axios.delete(
        `places/${item.reportedComment.activity.place}/activities/${item.reportedComment.activity.id}/comments/${item.reportedComment.id}`
      );
    }
  };

  const onPressDeleteReport = async (index: number, id: string) => {
    const rows = [...data.rows];
    rows.splice(index, 1);
    setData((prev) => {
      let state = structuredClone(prev);
      state.rows = rows;
      state.count = state.count - 1;
      return state;
    });

    const axios = await axiosRequest();
    axios.delete(`reports/${id}`);
  };

  useEffect(() => {
    getReports();
  }, [reportState]);

  return (
    <PageLayout>
      <div className={styles.reportsBlock}>
        <div className={styles.reportsBlock__header}>
          <div className={styles.reportsBlock__headerLeft}>
            <div className={styles.reportsBlock__headerTitle}>
              Reported comments
            </div>
            <div className={styles.reportsBlock__switcher}>
              <div
                className={clsx(styles.reportsBlock__switcherBg, {
                  [styles.reportsBlock__switcherBgRight]: reportState === 1,
                })}
              />
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setShowLoader(true);
                  setReportState(0);
                }}
                className={clsx(styles.reportsBlock__switcherItem, {
                  [styles.active]: reportState === 0,
                })}
              >
                ACTUAL TAB
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setShowLoader(true);
                  setReportState(1);
                }}
                className={clsx(styles.reportsBlock__switcherItem, {
                  [styles.active]: reportState === 1,
                })}
              >
                ARCHIVED TAB
              </div>
            </div>
          </div>
          {/* <div className={styles.reportsBlock__headerInput}>
            <SearchInput placeholder={"Search"} />
          </div> */}
        </div>
        <Table
          headers={REPORT_TABLE_COLUMNS.activity}
          loading={showLoader}
          items={data.rows}
          renderTableItem={(item: ReportModel, index) => {
            const reportedUri = !!item?.reported?.avatar
              ? item.reported.avatar
              : "";

            const reportedUserFullName = !!item?.reported?.firstName
              ? `${item.reported.firstName} ${item.reported.lastName}`
              : "";

            const reporterUri = !!item?.reporter?.avatar
              ? item.reporter.avatar
              : "";

            const reporterUserFullName = !!item?.reporter?.firstName
              ? `${item.reporter.firstName} ${item.reporter.lastName}`
              : "";

            return (
              <tr>
                <td>
                  <div className={styles.useBlock}>
                    {!!reportedUri ? (
                      <img
                        src={reportedUri}
                        className={styles.useBlock__avatar}
                      />
                    ) : (
                      <div className={styles.useBlock__emptyAvatar}>
                        {item.reported.firstName[0]}
                        {item.reported.lastName[0]}
                      </div>
                    )}
                    {reportedUserFullName}
                  </div>
                </td>

                <td>
                  <div className={styles.useBlock}>
                    {!!reporterUri ? (
                      <img
                        src={reporterUri}
                        className={styles.useBlock__avatar}
                      />
                    ) : (
                      <div className={styles.useBlock__emptyAvatar}>
                        {item?.reporter.firstName[0]}
                        {item?.reporter.lastName[0]}
                      </div>
                    )}
                    {reporterUserFullName}
                  </div>
                </td>

                <td>
                  <div className={styles.useBlock}>{item.reason}</div>
                </td>

                <td>
                  <div className={styles.buttons}>
                    {reportState === 0 ? (
                      <>
                        <ActionButton
                          isDisable={!item.reportedComment}
                          color={"red"}
                          text={"Delete comment"}
                          onClick={() =>
                            onPressDeleteComment(
                              index,
                              item?.reportedComment?.id
                            )
                          }
                          modalText={
                            "Are you sure you want to delete this comment?"
                          }
                        />
                        <ActionButton
                          color={"red"}
                          text={"Delete user"}
                          onClick={() =>
                            onPressDeleteUser(index, item?.reported?.id)
                          }
                          modalText={
                            "Are you sure you want to delete this user?"
                          }
                        />
                        <ActionButton
                          color={item?.reported?.isBaned ? "green" : "red"}
                          text={
                            item?.reported?.isBaned ? "Unbun user" : "Ban user"
                          }
                          onClick={() => onPressBan(index, item.reported.id)}
                          modalText={`Are you shure you want to ${
                            item?.reported?.isBaned ? "unban" : "ban"
                          } this user?`}
                        />
                        <ActionButton
                          color={"green"}
                          text={"Delete report"}
                          onClick={() => {
                            onPressDeleteReport(index, item.id);
                          }}
                          modalText={
                            "Are you sure you want to delete this report?"
                          }
                        />
                        <ActionButton
                          onClick={() => onPressArchive(index, item.id)}
                          color={"green"}
                          text={"Archive report"}
                        />
                        <ActionButton
                          isDisable={!item.reportedComment}
                          color={"blue"}
                          text={"Show comment"}
                          onClick={() => setChoosedReportIndex(index)}
                        />
                      </>
                    ) : (
                      <>
                        <ActionButton
                          color={"green"}
                          text={"Delete report"}
                          onClick={() => {
                            onPressDeleteReport(index, item.id);
                          }}
                          modalText={
                            "Are you sure you want to delete this report?"
                          }
                        />
                      </>
                    )}
                  </div>
                </td>
              </tr>
            );
          }}
        />
        <Pagination
          dataLength={data.count}
          currentPage={page}
          paginationLimit={PAGINATION_LIMIT}
          loadData={getReports}
          setPage={setPage}
        />

        {choosedReportIndex !== null && (
          <CommentModal
            report={data.rows[choosedReportIndex]}
            onClose={() => setChoosedReportIndex(null)}
          />
        )}
      </div>
    </PageLayout>
  );
};

export default CommentReports;
