import React, { FC } from "react";

import styles from "./styles.module.scss";
import clsx from "clsx";

type Props = {
  label: string;
  placeholder: string;
  error?: string;
  value: string;
  onChange: (val: string) => void;
  clearError: () => void;
};

const LoginInput: FC<Props> = ({
  label,
  placeholder,
  error,
  onChange,
  clearError,
}) => {
  return (
    <div>
      <div className={styles.label}>{label}</div>
      <input
        className={clsx(styles.input, {
          [styles.input__error]: !!error,
        })}
        placeholder={placeholder}
        onChange={(e) => {
          onChange(e.target.value);
          !!error && clearError();
        }}
      />
      {!!error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default LoginInput;
