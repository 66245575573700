import React, { useCallback, useEffect, useRef, useState } from "react";

import { PageLayout } from "~layouts";
import { ArrowLeft, MapMakrer } from "~assets/svg";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Dropdown, SearchDropdown } from "~components";
import { DescriptionRow } from "react-native-google-places-autocomplete";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import GoogleMapReact from "google-map-react";
import GoogleMap from "google-maps-react-markers";
import Autocomplete from "react-google-autocomplete";

import { Client } from "@googlemaps/google-maps-services-js";

import styles from "./styles.module.scss";
import { axiosRequest } from "~api";
import { GOOGLE_API_TOKEN } from "~constants";
import { CategoryModel } from "models/CategoryModel";
import { iconsPng } from "~assets/png";

const client = new Client({});

const FetchPlaces = ({}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchInput, setSearchInput] = useState("");
  const [showLoder, setShowLoader] = useState(false);
  const [error, setError] = useState("");

  const [categories, setCategories] = useState<CategoryModel[]>([]);
  const [showCategoryLoder, setShowCategoryLoader] = useState(false);
  const [category, setCategory] = useState<CategoryModel | null>(
    !!location.state?.category ? location.state.category : null
  );

  const [choosedPlace, setChoosedPlace] = useState<null | DescriptionRow>(null);
  const [placeDetails, setPlaceDetails] = useState<null | any>();

  const [defaultProps, setDefaultProps] = useState({
    center: {
      lat: 34.052235,
      lng: -118.243683,
    },
    zoom: 20,
  });

  const _mapRef = useRef(null);

  const [test, setTest] = useState<any>(null);
  const [test1, setTest1] = useState<any>(null);

  const renderMarkers = (map: any, maps: any) => {
    let marker = new maps.Marker({
      position: { lat: defaultProps.center.lat, lng: defaultProps.center.lng },
      map,
      title: "Hello World!",
    });
    return marker;
  };

  const [showButtonLoader, setShowButtonLoader] = useState(false);

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: GOOGLE_API_TOKEN,
    options: {
      fields: ["geometry"],
    },
  });

  console.log("placesService", placesService);
  console.log("placePredictions", placePredictions);
  console.log("placedetailes", placeDetails);

  const onPressItem = (descriptionRow: DescriptionRow) => {
    setShowLoader(true);
    placesService?.getDetails(
      { placeId: descriptionRow.place_id },
      (placeDetails: any) => {
        console.log(placeDetails);
        const lat = placeDetails.geometry.location.lat();
        const lng = placeDetails.geometry.location.lng();

        !!_mapRef?.current &&
          //@ts-ignore
          _mapRef.current.maps.Marker({
            position: {
              lat: defaultProps.center.lat,
              lng: defaultProps.center.lng,
            },
            title: "Hello World!",
          });

        setDefaultProps({ ...defaultProps, center: { lat, lng } });

        setChoosedPlace(descriptionRow);
        setPlaceDetails(placeDetails);

        setShowLoader(false);
      }
    );
  };

  console.log(defaultProps);

  const getCategories = async () => {
    setShowCategoryLoader(true);
    const axios = await axiosRequest();
    axios.get("categories").then((resp) => {
      setShowCategoryLoader(false);
      setCategories(resp.data.data.rows);
    });
  };

  console.log("_mapRef", _mapRef);

  console.log(placeDetails);

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <PageLayout>
      <div className={styles.fetchPlaces}>
        <div className={styles.fetchPlaces__title}>Fetch place detail</div>
        <div
          className={styles.fetchPlaces__backArrow}
          onClick={() => navigate("/places")}
        >
          <ArrowLeft />
        </div>

        <div className={styles.fetchPlaces__input}>
          <SearchDropdown
            disable={!!location.state?.category}
            value={
              !!location.state?.category
                ? location.state.title
                : !!choosedPlace
                ? choosedPlace.description
                : searchInput
            }
            onChange={(val) => {
              !!error && setError("");
              !!choosedPlace && setChoosedPlace(null);
              setSearchInput(val);
              getPlacePredictions({ input: val });
            }}
            label="Search place"
            placeholder="Search place"
            loading={isPlacePredictionsLoading || showLoder}
            data={placePredictions.map(
              (item: DescriptionRow) => item.description
            )}
            onPressItem={(val, index) => {
              !!error && setError("");
              onPressItem(placePredictions[index]);
            }}
            error={error}
          />
        </div>

        <div className={styles.fetchPlaces__map}>
          {!showLoder && (
            <GoogleMap
              apiKey={"AIzaSyDxdGgVDO13n8RUf9HwN9T2dfsqXHvKB5I"}
              defaultCenter={{
                lat: defaultProps.center.lat,
                lng: defaultProps.center.lng,
              }}
              defaultZoom={!!placeDetails ? 18 : 10}
              onGoogleApiLoaded={({ map, maps }: { map: any; maps: any }) =>
                !!placeDetails && renderMarkers(map, maps)
              }
              center={defaultProps.center}
              zoom={defaultProps.zoom}
              options={{
                styles: [
                  {
                    featureType: "all",
                    elementType: "labels",
                    stylers: [{ visibility: "on" }],
                  },
                ],
              }}
            ></GoogleMap>
          )}
        </div>

        {!!placeDetails && (
          <div className={styles.fetchPlaces__infoBlock}>
            <div className={styles.fetchPlaces__info}>
              <span>Google place:</span>
            </div>
            <div className={styles.fetchPlaces__info}>
              <span>Place Name:</span> {placeDetails.name}
            </div>
            <div className={styles.fetchPlaces__info}>
              <span> Place Address:</span> {placeDetails.formatted_address}
            </div>
            <div className={styles.fetchPlaces__info}>
              <span> Google Types:</span>{" "}
              {placeDetails.types.map((item: string, index: number) =>
                index < placeDetails.types.length - 1 ? `${item}, ` : `${item}`
              )}
            </div>
            <div className={styles.fetchPlaces__info}>
              <span>Google PlaceId:</span>{" "}
              <a
                target="_blank"
                href={`https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeDetails.place_id}&key=AIzaSyDxdGgVDO13n8RUf9HwN9T2dfsqXHvKB5I`}
              >
                {placeDetails.place_id}
              </a>
            </div>
          </div>
        )}

        {/* <div className={styles.fetchPlaces__bottom}>
          <div className={styles.fetchPlaces__bottomButton}>
            <Button
              loading={showButtonLoader}
              disable={isDisable}
              text={!!location.state?.category ? "Update" : "Save"}
              mode={"dark"}
              onClick={!!location.state?.category ? updatePlace : savePlace}
            />
          </div>
          <div className={styles.fetchPlaces__bottomButton}>
            <Button text="Cancel" mode={"light"} onClick={onPressCancel} />
          </div>
        </div> */}
      </div>
    </PageLayout>
  );
};

export default FetchPlaces;
