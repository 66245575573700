// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_notificationBlock__cMQ8N {\n  padding: 22px 24px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.styles_notificationBlock__message__zvrYx {\n  width: 208px;\n  font-weight: 400;\n  font-size: 10px;\n  line-height: 18px;\n  color: #2E2C34;\n}\n.styles_notificationBlock__messageShort__k\\+re4 {\n  width: 143px;\n}\n.styles_notificationBlock__time__WYGxo {\n  font-weight: 500;\n  font-size: 12px;\n  line-height: 18px;\n}\n.styles_notificationBlock__deleteButton__vPyoZ {\n  margin-top: 8px;\n}", "",{"version":3,"sources":["webpack://./src/pages/Notifications/Item/styles.module.scss"],"names":[],"mappings":"AAIA;EACI,kBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AAHJ;AAMI;EACI,YAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAJR;AAMQ;EACI,YAAA;AAJZ;AAQI;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AANR;AASI;EACI,eAAA;AAPR","sourcesContent":["@import '../../../scss/vars.scss';\n\n\n\n.notificationBlock{\n    padding: 22px 24px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n\n\n    &__message{\n        width: 208px;\n        font-weight: 400;\n        font-size: 10px;\n        line-height: 18px;\n        color: #2E2C34;\n\n        &Short{\n            width: 143px;\n        }\n    }\n   \n    &__time{\n        font-weight: 500;\n        font-size: 12px;\n        line-height: 18px;\n    }\n\n    &__deleteButton{\n        margin-top: 8px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notificationBlock": "styles_notificationBlock__cMQ8N",
	"notificationBlock__message": "styles_notificationBlock__message__zvrYx",
	"notificationBlock__messageShort": "styles_notificationBlock__messageShort__k+re4",
	"notificationBlock__time": "styles_notificationBlock__time__WYGxo",
	"notificationBlock__deleteButton": "styles_notificationBlock__deleteButton__vPyoZ"
};
export default ___CSS_LOADER_EXPORT___;
