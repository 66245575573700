import React, { FC, useState } from "react";

import "./calendar.styles.css";
import styles from "./styles.module.scss";
import moment from "moment";
import { CalendarArrow } from "~assets/svg";
import Calendar from "react-calendar";
import { getNewValue } from "~utils";
import clsx from "clsx";
import Button from "../Button";

type Props = {
  choosedDate: Date | null;
  saveDate: (val: Date) => void;
  saveButtonText?: string;
  defaultDate?: Date;
};

enum TimeType {
  am = "am",
  pm = "pm",
}

const CalendarBlock: FC<Props> = ({
  defaultDate,
  choosedDate,
  saveDate,
  saveButtonText = "Save",
}) => {
  const [calendarMonth, setCalendarMonth] = useState(new Date());

  const [date, setDate] = useState<null | Date>(
    !!defaultDate ? defaultDate : null
  );

  const getTime = (time: string) => {
    if (time.substring(0, 2).includes(":")) {
      return `0${time.substring(0, 4)}`;
    } else return time;
  };

  const defaultTime = !!defaultDate
    ? getTime(moment(defaultDate).format("LT").substring(0, 5))
    : "";

  const defaultTimeType: TimeType = !!defaultDate
    ? TimeType[
        moment(defaultDate)
          .format("LT")
          .substring(moment(defaultDate).format("LT").length - 2)
          .toLowerCase() as TimeType
      ]
    : TimeType.am;

  const [time, setTime] = useState(defaultTime);
  const [timeType, setTimType] = useState<TimeType>(defaultTimeType);

  const goToNextMonth = () => {
    let newDate = calendarMonth;
    newDate.setMonth(newDate.getMonth() + 1);
    setCalendarMonth(new Date(newDate));
  };

  const goToPrevMonth = () => {
    let newDate = calendarMonth;
    newDate.setMonth(newDate.getMonth() - 1);
    setCalendarMonth(new Date(newDate));
  };

  const onChangeInput = (e: any) => {
    const { isCorrect, val } = getNewValue(time, e.target.value);
    if (isCorrect) {
      setTime(val);
    }
  };

  const getFullDate = () => {
    let convDate = moment(date).format("MM/DD/YYYY");

    if (time.length < 5 || !date) return;

    if (timeType === TimeType.am) {
      convDate = convDate + ` ${time}`;
    } else {
      let timeParts = time.split(":");
      let hours = Number(timeParts[0]) + 12;

      convDate = convDate + ` ${hours}:${timeParts[1]}`;
    }

    let fullDateInMoment = moment(convDate, "MM/DD/YYYY hh:mm");

    //@ts-ignore
    let utcDate = new Date(fullDateInMoment).toString();

    let utcMoment = moment(utcDate).format("MM/DD/YYYY HH:mm");

    return { convDate: utcMoment, fullDateInMoment, date: new Date(utcMoment) };
  };

  let futureDate = getFullDate()?.fullDateInMoment;
  let currentDate = moment(new Date()).format("x");

  const dateInUtc = getFullDate()?.date;

  const isDisable =
    !futureDate ||
    moment(futureDate).format("x") < currentDate ||
    getFullDate()?.convDate ===
      moment(choosedDate).format("MM/DD/YYYY HH:mm") ||
    (!!defaultDate &&
      !!dateInUtc &&
      new Date(defaultDate).toString() === new Date(dateInUtc).toString());

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.header__title}>
          {moment(calendarMonth).format("MMMM")},{" "}
          {moment(calendarMonth).format("YYYY")}
        </div>

        <div className={styles.header__arrow} onClick={goToPrevMonth}>
          <CalendarArrow />
        </div>

        <div className={styles.header__arrowRotate} onClick={goToNextMonth}>
          <CalendarArrow />
        </div>
      </div>

      <div className={styles.timepicker}>
        <input placeholder="00:00" value={time} onChange={onChangeInput} />

        <div className={styles.timepicker__switcher}>
          <div
            className={clsx(styles.timepicker__switcherBg, {
              [styles.timepicker__switcherBgRight]: timeType === TimeType.pm,
            })}
          />
          <div
            onClick={() => setTimType(TimeType.am)}
            className={clsx(styles.timepicker__switcherItem, {
              [styles.active]: timeType === TimeType.am,
            })}
          >
            AM
          </div>
          <div
            onClick={() => setTimType(TimeType.pm)}
            className={clsx(styles.timepicker__switcherItem, {
              [styles.active]: timeType === TimeType.pm,
            })}
          >
            PM
          </div>
        </div>
      </div>

      <Calendar
        showNavigation={false}
        activeStartDate={calendarMonth}
        minDetail={"month"}
        value={date}
        minDate={new Date()}
        onClickDay={setDate}
      />

      <div className={styles.button}>
        <Button
          disable={isDisable}
          text={saveButtonText}
          mode={"dark"}
          onClick={() => !!dateInUtc && saveDate(dateInUtc)}
        />
      </div>
    </div>
  );
};

export default CalendarBlock;
