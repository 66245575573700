import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { PageLayout } from "~layouts";

import { iconsPng } from "~assets/png";

import {
  SearchInput,
  Table,
  DeleteButton,
  Pagination,
  Modal,
  ActionButton,
  Switch,
  ViewButton,
} from "~components";
import { axiosRequest } from "~api";
import { UserModel } from "~models";
import { LIST_TABLE } from "~constants";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { ArrowLeft } from "~assets/svg";

const PAGINATION_LIMIT = 20;
const UserPlaceList = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [hidePaginationPage, setHidePaginationPage] = useState(false);
  const [data, setData] = useState<any>({
    count: 0,
    rows: [],
  });

  console.log("location", location.state);

  const onPressDelete = async (index: number) => {
    const list = data.rows[index];
    setData((prev: any) => {
      let state = structuredClone(prev);
      state.rows.splice(index, 1);
      state.count = state.count - 1;
      return state;
    });
    const axios = await axiosRequest();
    axios.delete(`lists/${list.id}`);
  };

  const getUserList = async (newPage = 1) => {
    setShowLoader(true);
    const axios = await axiosRequest();
    await axios
      .get(
        `lists/user/${params.id}?page=${newPage}&limit=${PAGINATION_LIMIT}&sort=-createdAt&q=${searchValue}`
      )
      .then((resp) => {
        setHidePaginationPage(false);
        setShowLoader(false);
        setData(resp.data.data);
      })
      .catch((err) => console.log("getUserList ==> err", err.response));
  };

  const changeFeatured = async (index: number, id: string) => {
    const newFeatured = data.rows[index].isFeatured === true ? false : true;
    setData((prev: any) => {
      let state = structuredClone(prev);
      state.rows[index].isFeatured = newFeatured;
      return state;
    });

    const axios = await axiosRequest();
    axios.put(`lists/featured/${id}`, { isFeatured: newFeatured });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setHidePaginationPage(true);
      getUserList();
    }, 650);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);
  return (
    <PageLayout>
      <div className={styles.usersManagement}>
        <div className={styles.backArrow} onClick={() => navigate(`/users`)}>
          <ArrowLeft />
        </div>
        <div className={styles.usersManagement__header}>
          <div className={styles.usersManagement__headerTitle}>
            {!showLoader ? data?.rows[0]?.user?.fullName + " List" : null}
          </div>
          <div className={styles.usersManagement__headerInput}>
            <SearchInput
              value={searchValue}
              onChange={setSearchValue}
              placeholder={"Search for a list"}
            />
          </div>
        </div>

        <Table
          loading={showLoader}
          headers={LIST_TABLE}
          items={data.rows}
          renderTableItem={(item, index) => {
            return (
              <tr>
                <td>{item.title}</td>
                <td>{item.isCity ? "Yes" : "No"}</td>
                <td>
                  <Switch
                    value={item.isFeatured}
                    onClick={() => changeFeatured(index, item.id)}
                  />
                </td>
                <td style={{ paddingLeft: "5%" }}>{item.places.length}</td>
                <td>
                  {" "}
                  <ViewButton
                    handleClick={() => {
                      navigate(`places`, { state: item });
                    }}
                  />
                </td>

                <td>
                  <div className={styles.actionsBlock}>
                    <DeleteButton
                      subtitle="This will delete all the places inside this list"
                      onClick={() => onPressDelete(index)}
                    />
                  </div>
                </td>
              </tr>
            );
          }}
        />

        <Pagination
          currentPage={page}
          dataLength={data.count}
          paginationLimit={PAGINATION_LIMIT}
          setPage={setPage}
          loadData={getUserList}
          hidePaginationPage={hidePaginationPage}
        />
      </div>
    </PageLayout>
  );
};

export default UserPlaceList;
