import React from "react";

type Props = {
  fill: string;
};
const notifications = ({ fill }: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1505_1896"
        maskUnits="userSpaceOnUse"
        x="2"
        y="0"
        width="13"
        height="13"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0.666748H14.3313V12.2321H2V0.666748Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1505_1896)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.16468 1.66675C5.83468 1.66675 4.21068 3.49208 4.21068 5.13008C4.21068 6.51608 3.82601 7.15675 3.48601 7.72208C3.21334 8.17608 2.99801 8.53475 2.99801 9.31408C3.10934 10.5714 3.93934 11.2321 8.16468 11.2321C12.3667 11.2321 13.2227 10.5421 13.3333 9.27075C13.3313 8.53475 13.116 8.17608 12.8433 7.72208C12.5033 7.15675 12.1187 6.51608 12.1187 5.13008C12.1187 3.49208 10.4947 1.66675 8.16468 1.66675ZM8.16468 12.2321C5.04734 12.2321 2.23001 12.0121 2.00001 9.35675C1.99801 8.25808 2.33334 7.69941 2.62934 7.20741C2.92868 6.70875 3.21068 6.23875 3.21068 5.13008C3.21068 2.97475 5.20134 0.666748 8.16468 0.666748C11.128 0.666748 13.1187 2.97475 13.1187 5.13008C13.1187 6.23875 13.4007 6.70875 13.7 7.20741C13.996 7.69941 14.3313 8.25808 14.3313 9.31408C14.0987 12.0121 11.282 12.2321 8.16468 12.2321Z"
          fill={fill}
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.13267 15.0002H8.13134C7.384 14.9995 6.67667 14.6702 6.14 14.0722C5.95534 13.8675 5.972 13.5508 6.17734 13.3668C6.38267 13.1815 6.69867 13.1982 6.88334 13.4042C7.22867 13.7888 7.672 14.0002 8.132 14.0002H8.13267C8.59467 14.0002 9.04 13.7888 9.386 13.4035C9.57134 13.1988 9.88734 13.1822 10.092 13.3668C10.2973 13.5515 10.314 13.8682 10.1293 14.0728C9.59067 14.6708 8.882 15.0002 8.13267 15.0002Z"
        fill={fill}
      />
    </svg>
  );
};

export default notifications;
