import React, { useCallback, useEffect, useState } from "react";

import { PageLayout } from "~layouts";
import { ArrowLeft, Plus } from "~assets/svg";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  CalendarBlock,
  Input,
  SearchDropdown,
  Textarea,
} from "~components";
import { DescriptionRow } from "react-native-google-places-autocomplete";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useDropzone } from "react-dropzone";

import styles from "./styles.module.scss";
import { axiosRequest } from "~api";
import { BASE_URL, GOOGLE_API_TOKEN } from "~constants";
import { EventModel } from "~models";

type ImageType = {
  uri: string;
  name: string;
  type: string;
};

const CreateEvent = ({}) => {
  const navigate = useNavigate();

  const location = useLocation();

  const [searchInput, setSearchInput] = useState("");
  const [showLoder, setShowLoader] = useState(false);
  const [error, setError] = useState<{ link?: string }>({});

  const [name, setName] = useState("");
  const [description, setDecription] = useState("");
  const [link, setLink] = useState("");

  const [file, setFile] = useState<any>(null);
  const [date, setDate] = useState<Date | null>(null);
  const [choosedPlace, setChoosedPlace] = useState<null | DescriptionRow>(null);

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      apiKey: GOOGLE_API_TOKEN,
    });

  const onDrop = useCallback((acceptedFiles: any) => {
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
  });
  const uploadImageOnServer = async (image: ImageType) => {
    const axios = await axiosRequest(true);
    const formData = new FormData();

    formData.append("file", file);

    return await axios({
      method: "POST",
      url: `${BASE_URL}/files/upload`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer kf71b2ayZPDr2H+L05RjDifnRru6TEruRQR6oEs7ovc`,
      },
    }).catch((err) => console.log("ERR", err.response));
  };

  const onPressCancel = () => {
    setSearchInput("");
    setError({});
    setName("");
    setDecription("");
    setLink("");
    setFile(null);
    setDate(null);
    setChoosedPlace(null);
  };

  const onPressSave = async (id?: string) => {
    if (!!choosedPlace && !!file) {
      setShowLoader(true);

      let uri =
        typeof file === "string" ? file : await uploadImageOnServer(file);

      const data: {
        picture: string;
        name: string;
        location: string;
        date?: string;
        description?: string;
        link?: string;
      } = {
        name,
        location: choosedPlace.description,
        description,
        link,
        picture: typeof uri === "string" ? uri : uri?.data.data,
      };

      if (!!date) {
        data.date = date.toString();
      }

      const axios = await axiosRequest();
      if (!!id) {
        axios
          .put(`events/${id}`, data)
          .then(() => {
            setShowLoader(false);
            navigate("/events");
          })
          .catch((err) => {
            setShowLoader(false);
            setError({ [err.response.data.field]: err.response.data.message });
          });
        return;
      }
      axios
        .post(`events`, data)
        .then(() => {
          setShowLoader(false);
          navigate("/events");
        })
        .catch((err) => {
          setShowLoader(false);
          console.log("err", err.response);
          setError({ [err.response.data.field]: err.response.data.message });
        });
    }
  };

  const isDisableCancelButton =
    !searchInput &&
    !error &&
    !name &&
    !description &&
    !file &&
    !link &&
    !date &&
    !choosedPlace;

  const isDisableSaveButton = !name || !file || !choosedPlace;
  const isDisableUpdateButton = !!location.state?.event
    ? (location.state?.event.name === name &&
        location.state?.event.location === choosedPlace?.description &&
        location.state?.event.picture === file &&
        location.state?.event?.description === description &&
        new Date(location.state?.event?.date).toString() === date?.toString() &&
        location.state?.event?.link === link) ||
      !name ||
      !file ||
      !choosedPlace
    : false;

  useEffect(() => {
    if (!!location.state?.event) {
      const event: EventModel = location.state.event;
      setName(event.name);
      //@ts-ignore
      setChoosedPlace({ description: event.location });
      setFile(event.picture);

      !!event.date && setDate(new Date(event.date));
      !!event.link && setLink(event.link);
      !!event.description && setDecription(event.description);
    }
  }, []);

  return (
    <PageLayout>
      <div className={styles.createEvent}>
        <div className={styles.createEvent__title}>Create new event</div>
        <div
          className={styles.createEvent__backArrow}
          onClick={() => navigate("/events")}
        >
          <ArrowLeft />
        </div>

        {file === null ? (
          <div className={styles.createEvent__picker} {...getRootProps()}>
            <input {...getInputProps()} />
            <Plus fill={"#62B273"} />
            Add event picture
          </div>
        ) : (
          <div className={styles.createEvent__image} {...getRootProps()}>
            <img
              src={typeof file === "string" ? file : URL.createObjectURL(file)}
            />
          </div>
        )}

        <div className={styles.createEvent__input}>
          <Input
            value={name}
            onChange={setName}
            label={"Name"}
            placeholder={"Event name"}
          />
        </div>

        <div className={styles.createEvent__input}>
          <SearchDropdown
            value={!!choosedPlace ? choosedPlace.description : searchInput}
            onChange={(val) => {
              !!choosedPlace && setChoosedPlace(null);
              setSearchInput(val);
              getPlacePredictions({ input: val });
            }}
            label="Location"
            placeholder="Add location"
            loading={isPlacePredictionsLoading || showLoder}
            data={placePredictions.map(
              (item: DescriptionRow) => item.description
            )}
            onPressItem={(val, index) => {
              setChoosedPlace(placePredictions[index]);
            }}
          />
        </div>

        <div className={styles.createEvent__datePicker}>
          <CalendarBlock
            defaultDate={
              !!location.state?.event?.date
                ? new Date(location.state?.event?.date)
                : undefined
            }
            choosedDate={date}
            saveDate={setDate}
            saveButtonText={
              !!location.state?.event?.date ? "Update date" : "Save date"
            }
          />
        </div>

        <div className={styles.createEvent__input}>
          <Textarea
            value={description}
            onChange={setDecription}
            label={"Description"}
            placeholder={"Describe your event"}
          />
        </div>

        <div className={styles.createEvent__input}>
          <Input
            value={link}
            onChange={(val) => {
              setLink(val);
              !!error.link && setError({});
            }}
            label={"Link"}
            placeholder={"https://DJgneldengv129bcjq"}
            error={error.link}
          />
        </div>

        <div className={styles.createEvent__bottom}>
          <div className={styles.createEvent__bottomButton}>
            <Button
              loading={showLoder}
              disable={
                !!location.state?.event
                  ? isDisableUpdateButton
                  : isDisableSaveButton
              }
              text={!!location.state?.event ? "Update" : "Save"}
              mode={"dark"}
              onClick={
                !!location.state?.event
                  ? () => onPressSave(location.state?.event.id)
                  : onPressSave
              }
            />
          </div>
          <div className={styles.createEvent__bottomButton}>
            <Button
              disable={isDisableCancelButton}
              text="Cancel"
              mode={"light"}
              onClick={onPressCancel}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default CreateEvent;
