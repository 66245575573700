import React, { FC, useState } from "react";
import styles from "./styles.module.scss";
import { ViewIcon } from "~assets/svg";
// import { Modal } from "~components";
import clsx from "clsx";
type Props = {
  color?: "red" | "green";
  handleClick?: () => void;
  subtitle?: string | null;
};
const ViewButton: FC<Props> = ({ color = "green", handleClick, subtitle }) => {
  return (
    <>
      <div
        onClick={handleClick}
        className={clsx(styles.button, { [styles.green]: color === "green" })}
      >
        <ViewIcon fill={color} />
        View {subtitle}
      </div>
    </>
  );
};

export default ViewButton;
