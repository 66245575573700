import React, { useState, useCallback } from "react";

import styles from "./styles.module.scss";

import { Button, Modal } from "~components";
import { FC } from "react";
import { Comment } from "~assets/svg";
import ImageViewer from "react-simple-image-viewer";
import { ReportModel } from "~models";

type Props = {
  report: ReportModel;
  onClose: () => void;
};

const CommentModal: FC<Props> = ({ report, onClose }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const userAvatar = report.reported.avatar;
  const fullName = `${report.reported.firstName} ${report.reported.lastName}`;

  const comment =
    report.reportedCommentType === "PlaceActivity"
      ? report.reportedComment.comment
      : report.reportedComment.text;
  const photos =
    report.reportedCommentType === "PlaceActivity"
      ? report.reportedComment.attachments
      : [];

  console.log(report);

  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Modal buttonText={"Delete"} hideButtons={true}>
      <div>
        <div>
          <div className={styles.userBlock}>
            {!!userAvatar ? (
              <img src={userAvatar} className={styles.userBlock__avatar} />
            ) : (
              <div className={styles.userBlock__avatar} />
            )}
            {fullName}
          </div>
        </div>

        {!!comment && (
          <div className={styles.commentBlock}>
            <Comment />

            <div className={styles.commentBlock__field}>{comment}</div>
          </div>
        )}

        {photos.length > 0 && (
          <div className={styles.photosBlock}>
            {photos.map((item: string, index: number) => (
              <img
                onClick={() => openImageViewer(index)}
                src={item}
                alt="reported photo"
                className={styles.photosBlock__item}
              />
            ))}
          </div>
        )}

        <div className={styles.button}>
          <Button text="Close" onClick={onClose} mode={"light"} />
        </div>

        {isViewerOpen && (
          <ImageViewer
            src={photos}
            currentIndex={currentImage}
            disableScroll={true}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )}
      </div>
    </Modal>
  );
};

export default CommentModal;
