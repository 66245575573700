import React, { useEffect, useState } from "react";

import { PageLayout } from "~layouts";
import { ArrowLeft, PlusSquare } from "~assets/svg";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Input,
  Dropdown,
  TagsDropdown,
  Table,
  EditButton,
  DeleteButton,
  SearchInput,
  Switch,
} from "~components";

import styles from "./styles.module.scss";
import { CategoryModel } from "models/CategoryModel";
import { CATEGORIES_TABLE, TAGS_TABLE } from "~constants";
import { axiosRequest } from "~api";
import startCase from "lodash.startcase";

const CreatePlace = ({}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [addTag, setAddTag] = useState(false);

  const [category, setCategory] = useState<CategoryModel | null>(null);

  const [choosedTag, setChoosedTag] = useState<{
    item: { tag: string; isOnboarding: boolean };
    index: number;
  } | null>(null);
  const [editableTag, setEditableTag] = useState<string>("");
  const [switcherValue, setSwitcherValue] = useState(false);

  const [searchValue, setSearchValue] = useState<string>("");

  const [showLoader, setShowLoader] = useState(false);
  const onChangeSwitcherValue = () => {
    setSwitcherValue(!switcherValue);
  };

  const [sortedTags, setSortedTags] = useState<
    { tag: string; isOnboarding: boolean }[]
  >([]);

  const updateTag = async () => {
    const data = {
      newTag: editableTag,
      oldTag: choosedTag?.item.tag,
      isOnboarding: switcherValue,
    };

    setSortedTags((prev) => {
      let state = structuredClone(prev);
      if (choosedTag?.item !== undefined)
        state[choosedTag?.index] = {
          tag: editableTag,
          isOnboarding: switcherValue,
        };
      return state;
    });

    setCategory((prev) => {
      let state = structuredClone(prev);
      if (!!choosedTag?.item) {
        const index = state.tags.findIndex(
          (item: { tag: string }) => item.tag === choosedTag.item.tag
        );
        if (index > -1) {
          state.tags[index] = { tag: editableTag, isOnboarding: switcherValue };
        }
      }
      return state;
    });

    setEditableTag("");
    setSwitcherValue(false);
    setAddTag(false);
    setChoosedTag(null);

    const axios = await axiosRequest();
    axios.put(`categories/${category?.id}/tags/edit`, data);
  };

  const onPressRemoveTag = async (index: number) => {
    const currentTag = { ...sortedTags[index] };
    setSortedTags((prev) => {
      let state = structuredClone(prev);
      state.splice(index, 1);
      return state;
    });

    setCategory((prev) => {
      let state = structuredClone(prev);
      const tagIndex = category?.tags.findIndex(
        (item) => item.tag === currentTag.tag
      );
      if (!!tagIndex && tagIndex > -1) state.tags.splice(tagIndex, 1);
      return state;
    });

    const axios = await axiosRequest();
    axios.put(`categories/${category?.id}/tags/remove`, currentTag);
  };

  const saveTag = async () => {
    const tagObj = { tag: editableTag, isOnboarding: switcherValue };
    setEditableTag("");
    setSwitcherValue(false);
    setAddTag(false);
    setCategory((prev) => {
      let state = structuredClone(prev);
      state.tags.unshift(tagObj);
      return state;
    });
    setSortedTags((prev) => {
      let state = structuredClone(prev);
      state.unshift(tagObj);
      return state;
    });

    const axios = await axiosRequest();
    axios.put(`categories/${category?.id}/tags/add`, tagObj);
  };

  const isDisableSaveButton = !!choosedTag
    ? editableTag.length === 0 ||
      (editableTag === startCase(choosedTag.item.tag) &&
        switcherValue === choosedTag.item.isOnboarding)
    : editableTag.length === 0;

  const getCategory = async () => {
    setShowLoader(true);
    const axios = await axiosRequest();
    axios.get(`categories/${location.state.category.id}`).then((resp) => {
      // setCategory({ ...resp.data.data, tags: resp.data.data.tags.reverse() });
      // setSortedTags(resp.data.data.tags.reverse());

      setCategory(resp.data.data);
      setSortedTags(resp.data.data.tags.reverse());
      setShowLoader(false);
    });
  };

  const onChangeSearchValue = (val: string) => {
    setSearchValue(val);

    if (!!category?.tags) {
      setSortedTags(
        category.tags.filter((item) =>
          item.tag.toLowerCase().includes(val.toLowerCase())
        )
      );
    }
  };

  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(() => {
  //     if (searchValue.length > 0) {
  //       getPlaces();
  //     } else {
  //       setPlaces([]);
  //     }
  //   }, 650);

  //   return () => clearTimeout(delayDebounceFn);
  // }, [searchValue]);

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <PageLayout>
      <div className={styles.createPlace}>
        <div className={styles.createPlace__header}>
          <div className={styles.createPlace__headerTitle}>
            {location.state.category.title} : edit tags
          </div>

          <div className={styles.createPlace__headerRight}>
            {!!choosedTag || addTag ? (
              <div className={styles.createPlace__headerUpdate}>
                <div className={styles.createPlace__headerUpdateButton}>
                  <Button
                    disable={isDisableSaveButton}
                    text={addTag ? "Save tag" : "Update tag"}
                    mode={"dark"}
                    onClick={addTag ? saveTag : updateTag}
                  />
                </div>

                <div className={styles.createPlace__headerUpdateButton}>
                  <Button
                    text="Cancel"
                    mode={"light"}
                    onClick={() => {
                      setSwitcherValue(false);
                      setEditableTag("");
                      setChoosedTag(null);
                      setAddTag(false);
                    }}
                  />
                </div>
                <div className={styles.createPlace__headerUpdateInput}>
                  <Input
                    placeholder={addTag ? "Enter tag name" : "Change tag name"}
                    value={editableTag}
                    onChange={(val) => setEditableTag(val)}
                    label=""
                    hideLabel
                  />
                </div>
              </div>
            ) : (
              <>
                <div className={styles.createPlace__headerButton}>
                  <Button
                    text="Add tag"
                    mode={"dark"}
                    onClick={() => {
                      setAddTag(true);
                      onChangeSearchValue("");
                    }}
                    svg={<PlusSquare />}
                  />
                </div>
                <div className={styles.createPlace__headerInput}>
                  <SearchInput
                    value={searchValue}
                    onChange={onChangeSearchValue}
                    placeholder="Search for a user"
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className={styles.createPlace__headerBottom}>
          <div
            className={styles.createPlace__backArrow}
            onClick={() => navigate("/categories")}
          >
            <ArrowLeft />
          </div>

          {(!!choosedTag || addTag) && (
            <div className={styles.createPlace__headerSwitcher}>
              <span>Is onboarding:</span>
              <Switch value={switcherValue} onClick={onChangeSwitcherValue} />
            </div>
          )}
        </div>

        <Table
          loading={showLoader}
          headers={TAGS_TABLE}
          items={sortedTags}
          renderTableItem={(
            item: { tag: string; isOnboarding: boolean },
            index
          ) => {
            return (
              <tr>
                <td>
                  <div className={styles.name}>{startCase(item.tag)}</div>
                </td>
                <td>
                  <div className={styles.name}>{`${item.isOnboarding}`}</div>
                </td>
                <td>
                  <div className={styles.actionsBlock}>
                    <EditButton
                      onClick={() => {
                        setAddTag(false);
                        setSwitcherValue(item.isOnboarding);
                        setEditableTag(startCase(item.tag));
                        setChoosedTag({ item, index });
                      }}
                    />
                    <div className={styles.actionsBlock__deleteButton}>
                      <DeleteButton onClick={() => onPressRemoveTag(index)} />
                    </div>
                  </div>
                </td>
              </tr>
            );
          }}
        />
      </div>
    </PageLayout>
  );
};

export default CreatePlace;
