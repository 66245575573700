// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_container__GOptP {\n  max-width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 57px 44px;\n}\n.styles_container__GOptP .styles_content__\\+D8Y1 {\n  height: 100%;\n  width: 100%;\n  background-color: #FFFFFF;\n  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);\n  border-radius: 24px;\n}", "",{"version":3,"sources":["webpack://./src/layouts/PageLayout/styles.module.scss","webpack://./src/scss/vars.scss"],"names":[],"mappings":"AAGA;EACI,eAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AAFJ;AAKI;EACI,YAAA;EACA,WAAA;EACA,yBCLA;EDMA,4CAAA;EACA,mBAAA;AAHR","sourcesContent":["@import '../../scss/vars.scss';\n\n\n.container {\n    max-width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 57px 44px;\n\n\n    .content {\n        height: 100%;\n        width: 100%;\n        background-color: $white;\n        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);\n        border-radius: 24px;\n    }\n}","$black: #1D1D1D;\n$grayMedium: #AFAFAF;\n$gray: #E4E4E4;\n$grayLight: #F8F8F8;\n$grayInactive: #808191;\n$mainColor: #312E29;\n$secondary: #62B273;\n$secondaryInactive: #E9F9F1;\n$background: #F5ECE9;\n$red: #EB5757;\n$white: #FFFFFF;\n$grayText: #AAABAD;\n$primaryText: #2B2D33;\n$tableTH: #84818A;\n$seatle30: #D5D5D6;\n\n$inter: \"Inter\", sans-serif;\n$manrope: \"Manrope\", sans-serif;\n$poppins: \"Poppins\", sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "styles_container__GOptP",
	"content": "styles_content__+D8Y1"
};
export default ___CSS_LOADER_EXPORT___;
