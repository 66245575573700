import React from "react";

type Props = {
  fill: string;
};

const places = ({ fill }: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.16667 5.6665C7.52333 5.6665 7 6.18984 7 6.83384C7 7.47717 7.52333 7.99984 8.16667 7.99984C8.81 7.99984 9.33333 7.47717 9.33333 6.83384C9.33333 6.18984 8.81 5.6665 8.16667 5.6665ZM8.16667 8.99984C6.972 8.99984 6 8.0285 6 6.83384C6 5.6385 6.972 4.6665 8.16667 4.6665C9.36133 4.6665 10.3333 5.6385 10.3333 6.83384C10.3333 8.0285 9.36133 8.99984 8.16667 8.99984Z"
        fill={fill}
      />
      <mask
        id="mask0_1505_1907"
        maskUnits="userSpaceOnUse"
        x="2"
        y="1"
        width="12"
        height="14"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.66602 1.33325H13.6657V14.3333H2.66602V1.33325Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1505_1907)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.16602 2.33325C5.68468 2.33325 3.66602 4.37125 3.66602 6.87525C3.66602 10.0613 7.41535 13.1653 8.16602 13.3306C8.91668 13.1646 12.666 10.0606 12.666 6.87525C12.666 4.37125 10.6473 2.33325 8.16602 2.33325ZM8.16602 14.3333C6.97002 14.3333 2.66602 10.6319 2.66602 6.87525C2.66602 3.81925 5.13335 1.33325 8.16602 1.33325C11.1987 1.33325 13.666 3.81925 13.666 6.87525C13.666 10.6319 9.36202 14.3333 8.16602 14.3333Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default places;
