import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";

import { PageLayout } from "~layouts";
import { Button, Input, CalendarBlock, Pagination } from "~components";
import NotificationItem from "./Item";
import clsx from "clsx";
import { axiosRequest } from "~api";
import moment from "moment";
import { NotificationModel } from "~models";
import { Oval } from "react-loader-spinner";

const PAGINATION_LIMIT = 20;
const Notifications = () => {
  const [message, setMessage] = useState("");
  const [date, setDate] = useState<Date | null>(null);

  const [type, setType] = useState<"sent" | "scheduled">("sent");

  const [data, setData] = useState<{
    count: number;
    rows: NotificationModel[];
  }>({
    count: 0,
    rows: [],
  });

  const [showDataLoader, setShowDataLoader] = useState(false);
  const [page, setPage] = useState(1);
  const disableSendNow = !message;
  const disableSheduleMessage = !message || !date;

  const [showButtonLoader, setShowButtonLoader] = useState<
    null | "sent" | "scheduled"
  >(null);

  const sendNotification = async (notificationType: "sent" | "scheduled") => {
    const data: { content: string; date?: string } = {
      content: message,
    };

    if (notificationType === "scheduled" && !!date) {
      setShowButtonLoader("scheduled");
      data.date = date.toString();
    } else setShowButtonLoader("sent");

    const axios = await axiosRequest();
    axios.post(`notifications/${notificationType}`, data).then((resp) => {
      if (
        (notificationType === "sent" && type === "sent") ||
        (notificationType === "scheduled" && type === "scheduled")
      ) {
        setData((prev) => {
          let state = structuredClone(prev);
          state.rows.unshift(resp.data.data);
          if (state.rows.length > 20) state.rows.splice(20);
          state.count = state.count + 1;
          return state;
        });
      }

      setMessage("");
      setDate(null);
      setShowButtonLoader(null);
    });
  };

  const getNotification = async (newPage: number = 1) => {
    setShowDataLoader(true);

    const axios = await axiosRequest();
    axios
      .get(
        `notifications?status=${type}&page=${newPage}&sort=-createdAt&limit=${PAGINATION_LIMIT}`
      )
      .then((resp) => {
        setPage(newPage);
        setData(resp.data.data);
        setShowDataLoader(false);
      });
  };

  const onPressDelete = async (index: number, id: string) => {
    setData((prev) => {
      let state = structuredClone(prev);
      state.rows.splice(index, 1);
      state.count = state.count - 1;
      return state;
    });

    const axios = await axiosRequest();
    axios.delete(`notifications/${id}`);
  };

  useEffect(() => {
    getNotification();
  }, [type]);

  return (
    <PageLayout>
      <div className={styles.notificationsBlock}>
        <div>
          <div className={styles.notificationsBlock__header}>
            <div className={styles.notificationsBlock__headerTitle}>
              Send a custom notification
            </div>
          </div>
          <div className={styles.notificationsBlock__input}>
            <Input
              value={message}
              onChange={setMessage}
              label="Text notification"
              placeholder="Text notification"
            />
          </div>
          <div className={styles.notificationsBlock__buttons}>
            <div className={styles.notificationsBlock__buttonsItem}>
              <Button
                loading={showButtonLoader === "sent"}
                disable={disableSendNow}
                text="Send now"
                mode={"dark"}
                onClick={() => {
                  sendNotification("sent");
                }}
              />
            </div>

            <div className={styles.notificationsBlock__buttonsItem}>
              <Button
                loading={showButtonLoader === "scheduled"}
                disable={disableSheduleMessage}
                text="Schedule"
                mode={"light"}
                onClick={() => sendNotification("scheduled")}
              />
            </div>
          </div>

          <div className={styles.notificationsBlock__calendar}>
            <CalendarBlock choosedDate={date} saveDate={setDate} />
          </div>
        </div>
        <div className={styles.rightBlock}>
          <div className={styles.rightBlock__switcher}>
            <div
              className={clsx(styles.rightBlock__switcherBg, {
                [styles.rightBlock__switcherBgRight]: type === "scheduled",
              })}
            />
            <div
              onClick={() => {
                if (type === "scheduled") {
                  setShowDataLoader(true);
                  setType("sent");
                }
              }}
              className={clsx(styles.rightBlock__switcherItem, {
                [styles.active]: type === "sent",
              })}
            >
              SENT
            </div>
            <div
              onClick={() => {
                if (type === "sent") {
                  setShowDataLoader(true);
                  setType("scheduled");
                }
              }}
              className={clsx(styles.rightBlock__switcherItem, {
                [styles.active]: type === "scheduled",
              })}
            >
              SCHEDULED
            </div>
          </div>

          <div className={styles.rightBlock__header}>
            <div className={styles.rightBlock__headerTitle}>SENT</div>
            <div className={styles.rightBlock__headerTitle}>DATE</div>
          </div>

          <div className={styles.rightBlock__underline} />

          {showDataLoader && (
            <div className={styles.rightBlock__loader}>
              <Oval
                height={30}
                width={80}
                color="black"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#F5ECE9"
                strokeWidth={6}
                strokeWidthSecondary={6}
              />
            </div>
          )}

          <div className={styles.rightBlock__srollBlock}>
            {!showDataLoader &&
              data.rows.map((item, index) => {
                return (
                  <NotificationItem
                    isScheduled={type === "scheduled"}
                    notification={item}
                    onPressDelete={() => onPressDelete(index, item.id)}
                  />
                );
              })}
          </div>
          <Pagination
            dataLength={data.count}
            currentPage={page}
            setPage={setPage}
            loadData={getNotification}
            paginationLimit={PAGINATION_LIMIT}
          />
        </div>
      </div>
    </PageLayout>
  );
};

export default Notifications;
