import React from "react";

const importIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1505_1471"
        maskUnits="userSpaceOnUse"
        x="2"
        y="1"
        width="15"
        height="18"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.5 1.67651H16.7101V18.2211H2.5V1.67651Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1505_1471)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.31089 2.92651C4.93006 2.92651 3.78339 4.04485 3.75089 5.42401V14.3565C3.72089 15.774 4.83756 16.9407 6.24172 16.9715H12.9776C14.3684 16.9215 15.4692 15.774 15.4601 14.3607V6.94985L11.5984 2.92651H6.32089H6.31089ZM6.32089 18.2215H6.21589C4.12256 18.1765 2.45589 16.4365 2.50089 14.3432V5.40901C2.55006 3.34151 4.25672 1.67651 6.30839 1.67651H6.32339H11.8642C12.0342 1.67651 12.1967 1.74568 12.3151 1.86818L16.5367 6.26568C16.6476 6.38151 16.7101 6.53735 16.7101 6.69818V14.3565C16.7234 16.4473 15.0934 18.1465 12.9992 18.2215H6.32089Z"
          fill="#F8F8F8"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0815 7.48699H13.7865C12.2607 7.48283 11.0215 6.23949 11.0215 4.71616V2.29199C11.0215 1.94699 11.3015 1.66699 11.6465 1.66699C11.9915 1.66699 12.2715 1.94699 12.2715 2.29199V4.71616C12.2715 5.55282 12.9515 6.23449 13.7882 6.23699H16.0815C16.4265 6.23699 16.7065 6.51699 16.7065 6.86199C16.7065 7.20699 16.4265 7.48699 16.0815 7.48699Z"
        fill="#F8F8F8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.28516 13.917C8.94016 13.917 8.66016 13.637 8.66016 13.292V8.25781C8.66016 7.91281 8.94016 7.63281 9.28516 7.63281C9.63016 7.63281 9.91016 7.91281 9.91016 8.25781V13.292C9.91016 13.637 9.63016 13.917 9.28516 13.917Z"
        fill="#F8F8F8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.28372 13.9162C9.11789 13.9162 8.95789 13.8503 8.84122 13.732L6.88706 11.7703C6.64373 11.5253 6.64456 11.1295 6.88873 10.8861C7.13373 10.6428 7.52956 10.6428 7.77289 10.8878L9.28372 12.4061L10.7946 10.8878C11.0379 10.6428 11.4337 10.6428 11.6787 10.8861C11.9229 11.1295 11.9237 11.5253 11.6804 11.7703L9.72622 13.732C9.60956 13.8503 9.44956 13.9162 9.28372 13.9162Z"
        fill="#F8F8F8"
      />
    </svg>
  );
};

export default importIcon;
