import React, { FC, useState } from "react";

import styles from "./styles.module.scss";

import { PageLayout } from "~layouts";
import { SearchInput, Table, Pagination, ActionButton } from "~components";
import { iconsPng } from "~assets/png";
import clsx from "clsx";
import { ReportModel, ReportType } from "~models";
import { useEffect } from "react";
import { axiosRequest } from "~api";
import { REPORT_TABLE_COLUMNS } from "~constants";

const PAGINATION_LIMIT = 20;
const Feedback = () => {
  const [reportState, setReportState] = useState(0);
  const [data, setData] = useState({
    count: 0,
    rows: [],
  });
  const [page, setPage] = useState(1);
  const [showLoader, setShowLoader] = useState(false);

  const getReports = async (newPage: number = 1) => {
    setShowLoader(true);
    const axios = await axiosRequest();
    axios
      .get(
        `reports?type=${ReportType.feedback}&status=${
          reportState === 0 ? "active" : "archived"
        }&page=${newPage}&limit=${PAGINATION_LIMIT}`
      )
      .then((resp) => {
        setPage(newPage);
        setData(resp.data.data);
        setShowLoader(false);
        console.log("getReports ==> resp", resp);
      })
      .catch((err) => {
        console.log("getReports ==> err", err);
        setShowLoader(false);
      });
  };

  const onPressDeleteUser = async (index: number, id: string) => {
    setData((prev) => {
      let state = structuredClone(prev);
      state.rows.splice(index, 1);
      state.count = state.count - 1;
      return state;
    });

    const axios = await axiosRequest();
    axios.delete(`users/${id}`);
  };

  const onPressBan = async (index: number, userId: string) => {
    setData((prev) => {
      let state = structuredClone(prev);
      const newBanStatus = !state.rows[index].reporter.isBaned;
      state.rows.forEach((item: ReportModel, index: number) => {
        if (item.reporter?.id === userId)
          state.rows[index].reporter.isBaned = newBanStatus;
      });
      return state;
    });
    const axios = await axiosRequest();
    axios.put(`users/${userId}/ban`);
  };

  const onPressDeleteReport = async (index: number, id: string) => {
    const rows = [...data.rows];
    rows.splice(index, 1);
    setData((prev) => {
      let state = structuredClone(prev);
      state.rows = rows;
      state.count = state.count - 1;
      return state;
    });

    const axios = await axiosRequest();
    axios.delete(`reports/${id}`);
  };

  const onPressArchive = async (index: number, id: string) => {
    const rows = [...data.rows];
    rows.splice(index, 1);

    setData((prev) => {
      let state = structuredClone(prev);
      state.rows = rows;
      state.count = state.count - 1;
      return state;
    });
    const axios = await axiosRequest();
    axios.put(`reports/${id}`);
  };

  useEffect(() => {
    getReports();
  }, [reportState]);

  return (
    <PageLayout>
      <div className={styles.reportsBlock}>
        <div className={styles.reportsBlock__header}>
          <div className={styles.reportsBlock__headerLeft}>
            <div className={styles.reportsBlock__headerTitle}>Feedbacks</div>
            <div className={styles.reportsBlock__switcher}>
              <div
                className={clsx(styles.reportsBlock__switcherBg, {
                  [styles.reportsBlock__switcherBgRight]: reportState === 1,
                })}
              />
              <div
                onClick={() => {
                  setShowLoader(true);
                  setReportState(0);
                }}
                className={clsx(styles.reportsBlock__switcherItem, {
                  [styles.active]: reportState === 0,
                })}
              >
                ACTUAL TAB
              </div>
              <div
                onClick={() => {
                  setShowLoader(true);
                  setReportState(1);
                }}
                className={clsx(styles.reportsBlock__switcherItem, {
                  [styles.active]: reportState === 1,
                })}
              >
                ARCHIVED TAB
              </div>
            </div>
          </div>
          {/* <div className={styles.reportsBlock__headerInput}>
            <SearchInput placeholder={"Search"} />
          </div> */}
        </div>
        <Table
          headers={REPORT_TABLE_COLUMNS.feedback}
          loading={showLoader}
          items={data.rows}
          renderTableItem={(item, index) => {
            const reporterUri = !!item?.reporter?.avatar
              ? item.reporter.avatar
              : !!item?.reporter?.user?.avatar
              ? item.reporter.user.avatar
              : "";

            const reporterUserFullName = !!item?.reporter?.firstName
              ? `${item.reporter.firstName} ${item.reporter.lastName}`
              : !!item?.reporter?.user?.firstName
              ? `${item.reporter.user.firstName} ${item.reporter.user.lastName}`
              : "";

            return (
              <tr>
                <td>
                  <div className={styles.useBlock}>
                    {!!reporterUri ? (
                      <img
                        src={reporterUri}
                        className={styles.useBlock__avatar}
                      />
                    ) : (
                      <div className={styles.useBlock__emptyAvatar}>
                        {item?.reporter.firstName[0]}
                        {item?.reporter.lastName[0]}
                      </div>
                    )}
                    {reporterUserFullName}
                  </div>
                </td>

                <td>
                  <div className={styles.useBlock}>{item.reason}</div>
                </td>

                <td>
                  <div className={styles.useBlock}>{item.description}</div>
                </td>

                <td>
                  <div className={styles.buttons}>
                    {reportState === 0 ? (
                      <>
                        <ActionButton
                          color={"red"}
                          text={"Delete user"}
                          onClick={() =>
                            onPressDeleteUser(index, item.reporter.id)
                          }
                          modalText={
                            "Are you sure you want to delete this user?"
                          }
                        />
                        <ActionButton
                          color={item.reporter.isBaned ? "green" : "red"}
                          text={
                            item.reporter.isBaned ? "Unbun user" : "Ban user"
                          }
                          onClick={() => onPressBan(index, item.reporter.id)}
                          modalText={`Are you shure you want to ${
                            item?.reporter?.isBaned ? "unban" : "ban"
                          } this user?`}
                        />
                        <ActionButton
                          color={"green"}
                          text={"Delete report"}
                          onClick={() => onPressDeleteReport(index, item.id)}
                          modalText={
                            "Are you sure you want to delete this report?"
                          }
                        />
                        <ActionButton
                          color={"green"}
                          text={"Archive report"}
                          onClick={() => onPressArchive(index, item.id)}
                        />
                      </>
                    ) : (
                      <>
                        <ActionButton
                          color={"green"}
                          text={"Delete report"}
                          onClick={() => onPressDeleteReport(index, item.id)}
                          modalText={
                            "Are you sure you want to delete this report?"
                          }
                        />
                      </>
                    )}
                  </div>
                </td>
              </tr>
            );
          }}
        />
        <Pagination
          dataLength={data.count}
          currentPage={page}
          setPage={setPage}
          loadData={getReports}
          paginationLimit={PAGINATION_LIMIT}
        />
      </div>
    </PageLayout>
  );
};

export default Feedback;
