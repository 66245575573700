import React from "react";

type Props = {
  fill: string;
};

const users = ({ fill }: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9233 8.09783C11.678 8.09783 11.464 7.91717 11.4287 7.66717C11.3907 7.39317 11.5807 7.1405 11.8547 7.1025C12.548 7.00517 13.0713 6.40383 13.0727 5.7025C13.0727 5.00717 12.574 4.41917 11.8867 4.3065C11.6147 4.26183 11.43 4.0045 11.4747 3.73183C11.52 3.45917 11.7753 3.2765 12.0493 3.31983C13.2213 3.5125 14.0727 4.51517 14.0727 5.70383C14.07 6.9005 13.176 7.92717 11.9927 8.09317C11.9693 8.0965 11.946 8.09783 11.9233 8.09783Z"
        fill={fill}
      />
      <mask
        id="mask0_1505_1659"
        maskUnits="userSpaceOnUse"
        x="12"
        y="9"
        width="4"
        height="4"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6523 9.33447H15.1817V12.3542H12.6523V9.33447Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1505_1659)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.9079 12.3542C13.7065 12.3542 13.5165 12.2315 13.4405 12.0322C13.3419 11.7742 13.4719 11.4849 13.7299 11.3869C14.1819 11.2149 14.1819 11.0249 14.1819 10.9335C14.1819 10.6415 13.8105 10.4375 13.0785 10.3282C12.8059 10.2869 12.6172 10.0329 12.6579 9.75885C12.6985 9.48552 12.9519 9.30352 13.2272 9.33885C14.8425 9.58085 15.1819 10.3395 15.1819 10.9335C15.1819 11.2962 15.0392 11.9582 14.0859 12.3215C14.0272 12.3435 13.9672 12.3542 13.9079 12.3542Z"
          fill={fill}
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.92384 10.6094C6.33984 10.6094 4.45117 10.804 4.45117 11.7307C4.45117 12.6647 6.33984 12.8607 7.92384 12.8607C9.50784 12.8607 11.3958 12.6667 11.3958 11.742C11.3958 10.806 9.50784 10.6094 7.92384 10.6094ZM7.92384 13.8607C6.81784 13.8607 3.45117 13.8607 3.45117 11.7307C3.45117 9.60937 6.81784 9.60938 7.92384 9.60938C9.02984 9.60938 12.3958 9.60937 12.3958 11.742C12.3958 13.8607 9.14717 13.8607 7.92384 13.8607Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.92427 3.66675C6.79627 3.66675 5.87893 4.58475 5.87893 5.71275C5.87693 6.25941 6.08627 6.77075 6.4696 7.15675C6.85293 7.54275 7.3636 7.75675 7.9076 7.75875L7.92427 8.25875V7.75875C9.05227 7.75875 9.97027 6.84141 9.97027 5.71275C9.97027 4.58475 9.05227 3.66675 7.92427 3.66675ZM7.92427 8.75875H7.90627C7.09293 8.75608 6.33093 8.43741 5.76027 7.86141C5.18827 7.28608 4.8756 6.52208 4.87893 5.71075C4.87893 4.03341 6.24493 2.66675 7.92427 2.66675C9.60427 2.66675 10.9703 4.03341 10.9703 5.71275C10.9703 7.39208 9.60427 8.75875 7.92427 8.75875Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.92406 8.09771C3.90139 8.09771 3.87806 8.09638 3.85472 8.09305C2.67139 7.92705 1.77806 6.90038 1.77539 5.70505C1.77539 4.51505 2.62672 3.51238 3.79872 3.31971C4.07939 3.27571 4.32806 3.46038 4.37339 3.73171C4.41806 4.00438 4.23339 4.26171 3.96139 4.30638C3.27406 4.41905 2.77539 5.00705 2.77539 5.70371C2.77672 6.40371 3.30006 7.00571 3.99272 7.10238C4.26672 7.14038 4.45672 7.39305 4.41872 7.66705C4.38339 7.91705 4.16939 8.09771 3.92406 8.09771Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.94068 12.3543C1.88135 12.3543 1.82135 12.3436 1.76268 12.3216C0.808682 11.9576 0.666016 11.2956 0.666016 10.9336C0.666016 10.3403 1.00535 9.58096 2.62135 9.33896C2.89668 9.30429 3.14868 9.48562 3.19002 9.75896C3.23068 10.033 3.04202 10.287 2.76935 10.3283C2.03735 10.4376 1.66602 10.6416 1.66602 10.9336C1.66602 11.025 1.66602 11.2143 2.11868 11.387C2.37668 11.485 2.50668 11.7743 2.40802 12.0323C2.33202 12.2316 2.14202 12.3543 1.94068 12.3543Z"
        fill={fill}
      />
    </svg>
  );
};

export default users;
