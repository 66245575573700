import React, { ElementType, FC, SVGProps } from "react";

import clsx from "clsx";

import styles from "./styles.module.scss";

import { PlusSquare } from "~assets/svg";
import { Oval } from "react-loader-spinner";

type Props = {
  text: string;
  mode: "dark" | "light";
  disable?: boolean;
  svg?: JSX.Element;
  onClick: () => void;
  loading?: boolean;
};

const Button: FC<Props> = ({
  text,
  mode,
  disable,
  svg: Icon,
  onClick,
  loading,
}) => {
  return (
    <div
      onClick={() => !disable && !loading && onClick()}
      className={clsx(styles.button, {
        [styles.button__light]: mode === "light" && !disable,
        [styles.button__dark]: mode === "dark" && !disable,
        [styles.button__lightDisable]: mode === "light" && disable,
        [styles.button__darkDisable]: mode === "dark" && disable,
      })}
    >
      {!!Icon && <div>{Icon}</div>}
      <div
        className={clsx(styles.button__text, {
          [styles.button__textLight]: mode === "light" && !disable,
          [styles.button__textDark]: mode === "dark" && !disable,
          [styles.button__textLightDisable]: mode === "light" && disable,
          [styles.button__textDarkDisable]: mode === "dark" && disable,
        })}
      >
        {loading ? (
          <Oval
            height={30}
            width={80}
            color="#FFFFFF"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#F5ECE9"
            strokeWidth={6}
            strokeWidthSecondary={6}
          />
        ) : (
          text
        )}
      </div>
    </div>
  );
};

export default Button;
