import React from "react";

const arrowLeft = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.666 16.3657C26.666 16.872 26.2898 17.2904 25.8017 17.3566L25.666 17.3657L5.66602 17.3657C5.11373 17.3657 4.66602 16.918 4.66602 16.3657C4.66602 15.8595 5.04222 15.4411 5.53032 15.3749L5.66602 15.3657L25.666 15.3657C26.2183 15.3657 26.666 15.8134 26.666 16.3657Z"
        fill="black"
      />
      <path
        d="M14.438 23.6897C14.8294 24.0794 14.8307 24.7126 14.441 25.1039C14.0868 25.4597 13.5313 25.4932 13.1392 25.2036L13.0268 25.107L4.96017 17.075C4.60332 16.7197 4.57086 16.1622 4.8628 15.7701L4.96011 15.6578L13.0268 7.62444C13.4181 7.23472 14.0513 7.23603 14.441 7.62737C14.7953 7.98312 14.8264 8.53874 14.5351 8.92962L14.4381 9.04158L7.08335 16.3667L14.438 23.6897Z"
        fill="black"
      />
    </svg>
  );
};

export default arrowLeft;
