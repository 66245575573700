import React, { useEffect, useState } from "react";

import { PageLayout } from "~layouts";
import { ArrowLeft, Minuse, PlusIcon } from "~assets/svg";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Dropdown, SearchDropdown } from "~components";
import { DescriptionRow } from "react-native-google-places-autocomplete";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

import { Client } from "@googlemaps/google-maps-services-js";

import { countBy, startCase } from "lodash";
import styles from "./styles.module.scss";
import { axiosRequest } from "~api";
import { GOOGLE_API_TOKEN } from "~constants";
import { CategoryModel } from "models/CategoryModel";
import { iconsPng } from "~assets/png";

const client = new Client({});

const CreatePlace = ({}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchInput, setSearchInput] = useState("");
  const [showLoder, setShowLoader] = useState(false);
  const [error, setError] = useState("");

  const [categories, setCategories] = useState<CategoryModel[]>([]);
  const [showCategoryLoder, setShowCategoryLoader] = useState(false);
  const [category, setCategory] = useState<CategoryModel | null>(
    !!location.state?.category ? location.state.category : null
  );

  const [choosedPlace, setChoosedPlace] = useState<null | DescriptionRow>(null);

  const [showButtonLoader, setShowButtonLoader] = useState(false);

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: GOOGLE_API_TOKEN,
  });

  const [tags, setTags] = useState<string[]>(
    !!location.state ? location.state.tags : []
  );

  const getPlaceDetail = async (id: string, item: DescriptionRow) => {
    setShowLoader(true);

    const axios = await axiosRequest();
    axios
      .get(`places/${id}/check`)
      .then((resp) => {
        console.log("resp", resp.data.data);
        setChoosedPlace(item);
        setCategory(resp.data.data);
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err.response);
        setError(err.response.data.message);
        setShowLoader(false);
      });
  };

  const getCategories = async () => {
    setShowCategoryLoader(true);
    const axios = await axiosRequest();
    axios.get("categories").then((resp) => {
      setShowCategoryLoader(false);
      setCategories(resp.data.data.rows);
    });
  };

  const savePlace = async () => {
    setShowButtonLoader(true);
    const axios = await axiosRequest();
    axios
      .post(`places`, {
        category: category?.id,
        placeId: choosedPlace?.place_id,
      })
      .then((resp) => {
        setShowButtonLoader(false);
        navigate("/places");
      });
  };
  console.log(location.state);
  const updateCategoryAndTags = async () => {
    const axios = await axiosRequest();

    Promise.all([
      axios.put(`places/${location.state.id}`, { category: category?.id }),
      axios.put(`places/${location.state.id}/tags/manage`, { tags }),
    ]).then((resp) => {
      setShowButtonLoader(false);
      navigate("/places");
    });
  };

  const updatePlace = async () => {
    setShowButtonLoader(true);
    const axios = await axiosRequest();
    if (
      location.state.category.id !== category?.id &&
      JSON.stringify(location.state.tags) === JSON.stringify(tags)
    ) {
      axios
        .put(`places/${location.state.id}`, { category: category?.id })
        .then(() => {
          setShowButtonLoader(false);
          navigate("/places");
        });
    }

    if (
      location.state.category.id === category?.id &&
      JSON.stringify(location.state.tags) !== JSON.stringify(tags)
    ) {
      axios
        .put(`places/${location.state.id}/tags/manage`, { tags })
        .then(() => {
          setShowButtonLoader(false);
          navigate("/places");
        });
    }

    if (
      location.state.category.id !== category?.id &&
      JSON.stringify(location.state.tags) !== JSON.stringify(tags)
    ) {
      updateCategoryAndTags();
    }
  };

  const categoryTags = !!location.state ? location.state.category.tags : [];

  const onPressCancel = () => {
    if (!!location.state?.category) {
      setCategory(location.state.category);
      setTags(location.state.tags);
    } else {
      setChoosedPlace(null);
      setSearchInput("");
      setCategory(null);
      setError("");
    }
  };

  const onPressPlus = (key: string) => {
    setTags((prev) => {
      let state = [...prev];
      state.push(key);
      return state;
    });
  };

  const onPressMinuse = (key: string) => {
    setTags((prev) => {
      let state = [...prev];
      let index = state.lastIndexOf(key);
      index > -1 && state.splice(index, 1);
      return state;
    });
  };

  useEffect(() => {
    getCategories();
  }, []);

  const isDisable = !!location.state?.category
    ? location.state.category.id === category?.id &&
      JSON.stringify(location.state.tags) === JSON.stringify(tags)
    : !choosedPlace || !category;

  return (
    <PageLayout>
      <div className={styles.createPlace}>
        <div className={styles.createPlace__title}>Add new place</div>
        <div
          className={styles.createPlace__backArrow}
          onClick={() => navigate("/places")}
        >
          <ArrowLeft />
        </div>

        <div className={styles.createPlace__input}>
          <SearchDropdown
            disable={!!location.state?.category}
            value={
              !!location.state?.category
                ? location.state.title
                : !!choosedPlace
                ? choosedPlace.description
                : searchInput
            }
            onChange={(val) => {
              !!error && setError("");
              !!choosedPlace && setChoosedPlace(null);
              setSearchInput(val);
              getPlacePredictions({ input: val });
            }}
            label="Location"
            placeholder="Location"
            loading={isPlacePredictionsLoading || showLoder}
            data={placePredictions.map(
              (item: DescriptionRow) => item.description
            )}
            onPressItem={(val, index) => {
              !!error && setError("");
              getPlaceDetail(
                placePredictions[index].place_id,
                placePredictions[index]
              );
            }}
            error={error}
          />
        </div>

        <div className={styles.createPlace__input}>
          <Dropdown
            value={category?.title}
            label="Category"
            placeholder="Select a category"
            data={categories.map((item) => item.title)}
            loading={showCategoryLoder}
            onSave={(value, index) => setCategory(categories[index])}
          />
        </div>

        {/* {!!location.state && tags.length > 0 && (
          <div className={styles.createPlace__tagsBlock}>
            {Object.keys(tagsObj).map((item) => {
              return (
                <div className={styles.createPlace__tagsItem}>
                  <span>{startCase(item)}</span>

                  <div className={styles.createPlace__tagsItemRight}>
                    <div
                      onClick={() => {
                        onPressMinuse(item);
                      }}
                    >
                      <Minuse />
                    </div>

                    <span>{tagsObj[item]}</span>
                    <div
                      onClick={() => {
                        onPressPlus(item);
                      }}
                    >
                      <PlusIcon />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )} */}
        {!!location.state && location.state.category.tags.length > 0 && (
          <div className={styles.createPlace__tagsBlock}>
            {location.state.category.tags
              .map((item: { tag: string }) => item.tag)
              .map((item: string) => {
                const length = tags.filter((tag) => tag === item).length;
                return (
                  <div className={styles.createPlace__tagsItem}>
                    <span>{startCase(item)}</span>

                    <div className={styles.createPlace__tagsItemRight}>
                      <div
                        onClick={() => {
                          onPressMinuse(item);
                        }}
                      >
                        <Minuse />
                      </div>

                      <span>{length}</span>
                      <div
                        onClick={() => {
                          onPressPlus(item);
                        }}
                      >
                        <PlusIcon />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}

        <div className={styles.createPlace__bottom}>
          <div className={styles.createPlace__bottomButton}>
            <Button
              loading={showButtonLoader}
              disable={isDisable}
              text={!!location.state?.category ? "Update" : "Save"}
              mode={"dark"}
              onClick={!!location.state?.category ? updatePlace : savePlace}
            />
          </div>
          <div className={styles.createPlace__bottomButton}>
            <Button text="Cancel" mode={"light"} onClick={onPressCancel} />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default CreatePlace;
