import React, { useState } from "react";

import styles from "./styles.module.scss";
import { iconsPng } from "~assets/png";
import { LoginInput, Button } from "~components";
import { setSuperAdmin, setToken } from "~utils";
import { useNavigate } from "react-router-dom";
import { axiosRequest } from "~api";

const Login = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [showLoader, setShowLoader] = useState<boolean>(false);

  const [error, setError] = useState<{ email?: string; password?: string }>({});

  const login = async () => {
    setShowLoader(true);
    let axios = await axiosRequest();
    axios
      .post("auth/admin", { email, password })
      .then((resp) => {
        setShowLoader(false);
        console.log("resp.data.data", resp.data.data);
        setToken(resp.data.data.token);
        setSuperAdmin(`${resp.data.data.user.role !== "admin"}`);
        navigate("/");
      })
      .catch((err) => {
        setShowLoader(false);
        setError({ [err.response.data.field]: err.response.data.message });
      });
  };

  return (
    <div className={styles.login}>
      <div className={styles.login__modal}>
        <img
          src={iconsPng.logo}
          alt="Logo"
          className={styles.login__modalLogo}
        />

        <div className={styles.login__modalInput}>
          <LoginInput
            clearError={() => setError({})}
            value={email}
            onChange={setEmail}
            label="User email"
            placeholder="Enter user email"
            error={error?.email}
          />
        </div>
        <div className={styles.login__modalInput}>
          <LoginInput
            clearError={() => setError({})}
            value={password}
            onChange={setPassword}
            label="Password"
            placeholder="********"
            error={error?.password}
          />
        </div>
        <div className={styles.login__modalButton}>
          <Button
            loading={showLoader}
            disable={!email || !password || Object.keys(error).length > 0}
            text="Login"
            mode={"dark"}
            onClick={login}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
