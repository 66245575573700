import React, { useState } from "react";

import { PageLayout } from "~layouts";
import { ArrowLeft } from "~assets/svg";
import { useNavigate } from "react-router-dom";
import { Button, Input, Modal } from "~components";

import styles from "./styles.module.scss";
import { axiosRequest } from "~api";

const CreateAdmin = ({}) => {
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState<{ email?: string; password?: string }>({});

  const [showLoader, setShowLoader] = useState(false);

  const createAdmin = async () => {
    setShowModal(false);
    setShowLoader(true);
    const axios = await axiosRequest();
    axios
      .post("admins", { email, name: fullName, password })
      .then((resp) => {
        setShowLoader(false);
        navigate("/admins");
        console.log("RESP", resp);
      })
      .catch((err) => {
        setShowLoader(false);
        console.log(err.response);
        setError({ [err.response.data.field]: err.response.data.message });
      });
  };

  return (
    <PageLayout>
      <div className={styles.createAdmin}>
        <div className={styles.createAdmin__title}>Add new admin</div>
        <div
          className={styles.createAdmin__backArrow}
          onClick={() => navigate("/admins")}
        >
          <ArrowLeft />
        </div>

        <div className={styles.createAdmin__input}>
          <Input
            value={fullName}
            onChange={setFullName}
            label="Full name"
            placeholder="Your full name"
          />
        </div>
        <div className={styles.createAdmin__input}>
          <Input
            value={email}
            onChange={setEmail}
            label="Email"
            placeholder="Enter your email"
            error={error.email}
          />
        </div>
        <div className={styles.createAdmin__input}>
          <Input
            value={password}
            onChange={setPassword}
            label="Password"
            placeholder="Enter your password"
          />
        </div>
        <div className={styles.createAdmin__input}>
          <Input
            value={confirmPassword}
            onChange={setConfirmPassword}
            label="Confirm password"
            placeholder="Confirm your password"
          />
        </div>
        <div className={styles.createAdmin__button}>
          <Button
            loading={showLoader}
            disable={
              !email ||
              !fullName ||
              password.length < 4 ||
              password !== confirmPassword
            }
            text="Save"
            mode={"dark"}
            onClick={() => setShowModal(true)}
          />
        </div>

        {showModal && (
          <Modal
            buttonText={"Add"}
            onClose={() => setShowModal(false)}
            onClick={() => createAdmin()}
          >
            <div className={styles.createAdmin__modalTitle}>
              Add a new admin?
            </div>
          </Modal>
        )}
      </div>
    </PageLayout>
  );
};

export default CreateAdmin;
