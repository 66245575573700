import axios from "axios";
import { BASE_URL } from "~constants";
import { getToken } from "~utils";

export const axiosRequest = async (takeDefaultToken?: boolean) => {
  let token = await getToken();

  if (takeDefaultToken) {
    token = "kf71b2ayZPDr2H+L05RjDifnRru6TEruRQR6oEs7ovc";
  }

  const axiosClient = await axios.create({
    baseURL: `${BASE_URL}/`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return axiosClient;
};
