import React from "react";

const search = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.16732 2.33325C9.83669 2.33325 12.0007 4.49721 12.0007 7.16659C12.0007 8.26073 11.6371 9.26996 11.0242 10.0801L13.8054 12.8618C14.0657 13.1222 14.0657 13.5443 13.8054 13.8047C13.545 14.065 13.1229 14.065 12.8626 13.8047L10.0808 11.0234C9.27069 11.6364 8.26146 11.9999 7.16732 11.9999C4.49794 11.9999 2.33398 9.83596 2.33398 7.16659C2.33398 4.49721 4.49794 2.33325 7.16732 2.33325ZM7.16732 3.66659C5.23432 3.66659 3.66732 5.23359 3.66732 7.16659C3.66732 9.09958 5.23432 10.6666 7.16732 10.6666C9.10031 10.6666 10.6673 9.09958 10.6673 7.16659C10.6673 5.23359 9.10031 3.66659 7.16732 3.66659Z"
        fill="white"
      />
    </svg>
  );
};

export default search;
