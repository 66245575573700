import React, { FC } from "react";

import styles from "./styles.module.scss";
import clsx from "clsx";

type Props = {
  label: string;
  placeholder: string;
  error?: string;
  value?: string;
  onChange?: (val: string) => void;
  hideLabel?: boolean;
  disabled?: boolean;
};

const Input: FC<Props> = ({
  label,
  placeholder,
  error,
  value,
  onChange,
  hideLabel,
  disabled,
}) => {
  return (
    <div className={styles.container}>
      {!hideLabel && <div className={styles.label}>{label}</div>}

      <input
        disabled={disabled}
        value={value}
        className={clsx(styles.input, { [styles.input__error]: !!error })}
        placeholder={placeholder}
        onChange={(e) => !!onChange && onChange(e.target.value)}
      />
      {!!error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default Input;
