import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";

import { PageLayout } from "~layouts";
import {
  Button,
  SearchInput,
  Table,
  EditButton,
  DeleteButton,
  Pagination,
  Modal,
  UploadButton,
} from "~components";
import { PlusSquare, ImportIcon } from "~assets/svg";
import { useNavigate } from "react-router-dom";
import { EVENTS_TABLE, PLACES_TABLE } from "~constants";
import { EventModel, PlaceModel } from "~models";
import { axiosRequest } from "~api";
import moment from "moment";

const PAGINATION_LIMIT = 20;
const Events = () => {
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [data, setData] = useState<{ count: number; rows: EventModel[] }>({
    count: 0,
    rows: [],
  });
  const [page, setPage] = useState(1);

  const onPressDelete = async (index: number, id: string) => {
    setData((prev) => {
      let state = structuredClone(prev);
      state.count = state.count - 1;
      state.rows.splice(index, 1);
      return state;
    });

    const axios = await axiosRequest();
    axios.delete(`events/${id}`);
  };

  const getEvents = async (newPage: number = 1) => {
    setLoader(true);
    const axios = await axiosRequest();
    axios
      .get(`events?page=${newPage}&limit=${PAGINATION_LIMIT}`)
      .then((resp) => {
        setPage(newPage);
        setLoader(false);
        setData(resp.data.data);
      });
  };

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <PageLayout>
      <div className={styles.eventsBlock}>
        <div className={styles.eventsBlock__header}>
          <div className={styles.eventsBlock__headerTitle}>Events</div>

          <div className={styles.eventsBlock__headerRight}>
            <div className={styles.eventsBlock__headerButton}>
              <Button
                text="Add event"
                mode={"dark"}
                onClick={() => navigate("/events/create")}
                svg={<PlusSquare />}
              />
            </div>
          </div>
        </div>

        <Table
          loading={loader}
          headers={EVENTS_TABLE}
          items={data.rows}
          renderTableItem={(item: EventModel, index: number) => {
            return (
              <tr>
                <td>
                  <img src={item.picture} />
                </td>
                <td>
                  <div className={styles.paragraph}>{item.name}</div>
                </td>
                <td>
                  <div className={styles.location}>{item.location}</div>
                </td>
                <td>
                  <div className={styles.description}>
                    {!!item?.description
                      ? item?.description?.length > 200
                        ? item?.description.substring(0, 200) + "..."
                        : item?.description
                      : ""}
                  </div>
                </td>
                <td>
                  {item.date && (
                    <div className={styles.loaction}>
                      {moment(item.date).format("MM/DD/YYYY hh:mm a")}
                    </div>
                  )}
                </td>
                <td>
                  <div className={styles.actionsBlock}>
                    <EditButton
                      onClick={() =>
                        navigate("/events/create", { state: { event: item } })
                      }
                    />
                    <DeleteButton
                      color={"red"}
                      onClick={() => onPressDelete(index, item.id)}
                    />
                  </div>
                </td>
              </tr>
            );
          }}
        />
        <Pagination
          dataLength={data.count}
          paginationLimit={PAGINATION_LIMIT}
          currentPage={page}
          setPage={setPage}
          loadData={getEvents}
        />
      </div>
    </PageLayout>
  );
};

export default Events;
