import React from "react";

const edit = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.2885 11.1846H3.8765C2.156 11.1846 1 9.97705 1 8.18005V4.02305C1 2.22605 2.156 1.01855 3.8765 1.01855H5.746C5.953 1.01855 6.121 1.18655 6.121 1.39355C6.121 1.60055 5.953 1.76855 5.746 1.76855H3.8765C2.5845 1.76855 1.75 2.65355 1.75 4.02305V8.18005C1.75 9.54955 2.5845 10.4346 3.8765 10.4346H8.2885C9.5805 10.4346 10.4155 9.54955 10.4155 8.18005V6.16605C10.4155 5.95905 10.5835 5.79105 10.7905 5.79105C10.9975 5.79105 11.1655 5.95905 11.1655 6.16605V8.18005C11.1655 9.97705 10.009 11.1846 8.2885 11.1846Z"
        fill="#62B273"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.43344 7.71424H5.92194C6.11194 7.71424 6.28994 7.64074 6.42444 7.50624L10.1789 3.75174C10.3329 3.59774 10.4179 3.39274 10.4179 3.17474C10.4179 2.95624 10.3329 2.75074 10.1789 2.59674L9.57044 1.98824C9.25194 1.67074 8.73394 1.67074 8.41494 1.98824L4.67844 5.72474C4.54894 5.85424 4.47544 6.02624 4.47094 6.20874L4.43344 7.71424ZM5.92194 8.46425H4.04894C3.94794 8.46425 3.85094 8.42324 3.78044 8.35075C3.70994 8.27875 3.67144 8.18125 3.67394 8.07975L3.72094 6.19024C3.73044 5.81424 3.88194 5.46074 4.14794 5.19424H4.14844L7.88494 1.45774C8.49594 0.847744 9.48944 0.847744 10.1004 1.45774L10.7089 2.06624C11.0054 2.36224 11.1684 2.75574 11.1679 3.17474C11.1679 3.59374 11.0049 3.98674 10.7089 4.28224L6.95444 8.03675C6.67894 8.31224 6.31194 8.46425 5.92194 8.46425Z"
        fill="#62B273"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.86491 4.95842C9.76891 4.95842 9.67291 4.92192 9.59991 4.84842L7.31691 2.56542C7.17041 2.41892 7.17041 2.18142 7.31691 2.03492C7.46341 1.88842 7.70041 1.88842 7.84691 2.03492L10.1299 4.31842C10.2764 4.46492 10.2764 4.70192 10.1299 4.84842C10.0569 4.92192 9.96091 4.95842 9.86491 4.95842Z"
        fill="#62B273"
      />
    </svg>
  );
};

export default edit;
