import React, { FC } from "react";

import styles from "./styles.module.scss";

import { Search } from "~assets/svg";

type Props = {
  placeholder: string;
  value?: string;
  onChange?: (val: string) => void;
};

const SearchInput: FC<Props> = ({ placeholder, value, onChange }) => {
  return (
    <div className={styles.inputBlock}>
      <input
        value={value}
        onChange={(e) => !!onChange && onChange(e.target.value)}
        placeholder={placeholder}
      />
      <div className={styles.inputBlock__button}>
        <Search />
      </div>
    </div>
  );
};

export default SearchInput;
