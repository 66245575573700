import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "~pages/Login";
import PrivateRoute from "./PrivateRoute";
import UsersManagement from "~pages/UsersManagement";
import AdminsManagement from "~pages/AdminsManagement";
import MobileAdminsManagement from "~pages/MobileAdminsManagement";
import Categories from "~pages/Categories";
import Archtypes from "~pages/Archtypes";
import Notifications from "~pages/Notifications";
import PlacesManagement from "~pages/PlacesManagement";
import EditCategory from "~pages/EditCategory";
import Reports from "~pages/Reports";

import CommentReports from "~pages/CommentReports";
import UserReports from "~pages/UserReports";
import GeneralReports from "~pages/GeneralReports";
import Feedback from "~pages/Feedback";

import CreateAdmin from "~pages/CreateAdmin";
import CreateArchtype from "~pages/CreateArchtype";
import CreatePlace from "~pages/CreatePlace";
import EditTags from "~pages/EditTags";
import Events from "~pages/Events";
import CreateEvent from "~pages/CreateEvent";
import FetchPlaces from "~pages/FetchPlaces";
import ImportPlaces from "~pages/ImportPlaces";
import { ReportType } from "~models";
import UserDetail from "~pages/UsersManagement/userDetail";
import UserPlaceList from "~pages/UsersManagement/userPlaceList";
import UserPlaces from "~pages/UsersManagement/userPlaces";

const MainNavigation = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Login />} path="/login" />
        <Route path="/" element={<PrivateRoute />}>
          <Route index element={<Navigate to="/users" />} />
          <Route element={<UsersManagement />} path="/users" />
          <Route element={<UserDetail />} path="/users/:id" />
          <Route element={<UserPlaceList />} path="/users/:id/list" />
          <Route element={<UserPlaces />} path="/users/:id/list/places" />
          <Route element={<AdminsManagement />} path="/admins" />
          <Route element={<MobileAdminsManagement />} path="/mobile/admins" />
          <Route element={<Categories />} path="/categories" />
          <Route element={<EditTags />} path="/categories/tags" />
          {/* <Route element={<EditCategory />} path="/categories/edit" /> */}
          <Route element={<Archtypes />} path="/archtypes" />
          <Route element={<Notifications />} path="/notifications" />
          <Route element={<PlacesManagement />} path="/places" />
          <Route element={<CommentReports />} path="/reports/comment" />
          <Route element={<UserReports />} path="/reports/user" />
          <Route element={<GeneralReports />} path="/reports/general" />
          <Route element={<Feedback />} path="/reports/feedback" />

          <Route element={<CreateAdmin />} path="/admins/create" />
          <Route element={<EditCategory />} path="/categories/edit" />
          <Route element={<CreateArchtype />} path="/archtypes/create" />
          <Route element={<CreatePlace />} path="/places/create" />
          <Route element={<FetchPlaces />} path="/places/fetch" />
          <Route element={<ImportPlaces />} path="/places/import" />
          <Route element={<Events />} path="/events" />
          <Route element={<CreateEvent />} path="/events/create" />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default MainNavigation;
