import React, { FC, useState, useRef } from "react";

import styles from "./styles.module.scss";
import clsx from "clsx";

import { ArrowDown, Close, EditPen } from "~assets/svg";
import { useOutsideClick } from "~hooks";

type Props = {
  label: string;
  placeholder: string;
  error?: string;
  data: { tag: string; isOnboarding: boolean }[];
  value?: string;
  onSave: (item: any, index: number) => void;
};

const TagsDropdown: FC<Props> = ({
  label,
  placeholder,
  error,
  data,
  value,
  onSave,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOutsideClick({ ref: dropdownRef, callback: () => setIsOpen(false) });

  return (
    <div ref={dropdownRef}>
      <div className={styles.label}>{label}</div>

      <div
        onClick={() => setIsOpen(!isOpen)}
        className={clsx(styles.field, {
          [styles.input__error]: !!error,
          [styles.active]: isOpen,
        })}
      >
        {!!value ? (
          <div className={styles.field__text}>{value}</div>
        ) : (
          <div className={styles.field__placeholder}>{placeholder}</div>
        )}

        <div className={clsx(styles.field__arrow, { [styles.active]: isOpen })}>
          <ArrowDown fill={"#AFAFAF"} />
        </div>
      </div>
      <div
        className={clsx(styles.selectBlock, {
          [styles.active60]: isOpen && data.length === 1,
          [styles.active105]: isOpen && data.length === 2,
          [styles.active150]: isOpen && data.length > 2,
        })}
      >
        {data.map((item, index) => (
          <div
            onClick={() => {
              onSave(item, index);
              setIsOpen(false);
            }}
            key={`${item.tag}`}
            className={styles.selectBlock__item}
          >
            {item.tag}

            <div className={styles.selectBlock__itemButtons}>
              {/* <div>
                <EditPen />
              </div> */}
              <div>
                <Close />
              </div>
            </div>
          </div>
        ))}
      </div>

      {!!error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default TagsDropdown;
