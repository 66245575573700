import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";

import { PageLayout } from "~layouts";
import {
  Button,
  SearchInput,
  Table,
  EditButton,
  DeleteButton,
  Pagination,
  Modal,
} from "~components";
import { PlusSquare } from "~assets/svg";
import { useNavigate } from "react-router-dom";
import { axiosRequest } from "~api";
import { AdminModel } from "~models";

const PAGINATION_LIMIT = 20;
const AdminsManagement = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<{ count: number; rows: AdminModel[] }>({
    count: 0,
    rows: [],
  });
  const [page, setPage] = useState(1);

  const [choosedAdminIndex, setChoosedAdminIndex] = useState<number | null>(
    null
  );

  const [showLoader, setShowLoader] = useState(false);

  const addAdmin = () => {
    navigate("/admins/create");
  };

  const getAdmins = async (newPage: number = 1) => {
    setShowLoader(true);
    const axios = await axiosRequest();
    axios
      .get("admins")
      .then((resp) => {
        setPage(newPage);
        setShowLoader(false);
        setData(resp.data.data);
        console.log("getAdmins --> resp", resp);
      })
      .catch((e) => {
        console.log("getAdmins --> error");
      });
  };

  const deleteAdmin = async () => {
    const axios = await axiosRequest();
    if (choosedAdminIndex !== null) {
      let adminId = data.rows[choosedAdminIndex].id;
      setData((prev) => {
        let state = JSON.parse(JSON.stringify(prev));
        state.rows.splice(choosedAdminIndex, 1);
        return state;
      });
      setChoosedAdminIndex(null);
      axios.delete(`admins/${adminId}`);
    }
  };

  useEffect(() => {
    getAdmins();
  }, []);

  return (
    <PageLayout>
      <div className={styles.adminsManagement}>
        <div className={styles.adminsManagement__header}>
          <div className={styles.adminsManagement__headerTitle}>
            Admin platform users
          </div>

          <div className={styles.adminsManagement__headerRight}>
            <div className={styles.adminsManagement__headerButton}>
              <Button
                text="Add new admin"
                mode={"dark"}
                onClick={addAdmin}
                svg={<PlusSquare />}
              />
            </div>

            {/* <div className={styles.adminsManagement__headerInput}>
              <SearchInput placeholder="Search for a user" />
            </div> */}
          </div>
        </div>

        <Table
          headers={["FULL NAME", "EMAIL", "ACCOUNT STATUS", "ACTIONS"]}
          items={data.rows}
          loading={showLoader}
          renderTableItem={(item: any, index: number) => {
            const { name, email, role, actins } = item;

            return (
              <tr>
                <td>
                  <div className={styles.name}>{name}</div>
                </td>
                <td>
                  <div className={styles.email}>{email}</div>
                </td>
                <td>
                  <div className={styles.status}>{role}</div>
                </td>
                <td>
                  <div className={styles.actionsBlock}>
                    {/* <EditButton /> */}
                    <div className={styles.actionsBlock__deleteButton}>
                      <DeleteButton
                        onClick={() => {
                          setChoosedAdminIndex(index);
                        }}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            );
          }}
        />

        <Pagination
          dataLength={data.count}
          paginationLimit={PAGINATION_LIMIT}
          currentPage={page}
          setPage={setPage}
          loadData={getAdmins}
        />

        {typeof choosedAdminIndex === "number" && (
          <Modal
            buttonText={"Delete"}
            onClose={() => setChoosedAdminIndex(null)}
            onClick={() => deleteAdmin()}
          >
            <div className={styles.adminsManagement__modal}>
              <div className={styles.adminsManagement__modalTitle}>
                Are you sure you want to delete it?
              </div>
            </div>
          </Modal>
        )}
      </div>
    </PageLayout>
  );
};

export default AdminsManagement;
