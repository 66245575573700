import Button from "./Button";
import LoginInput from "./LoginInput";
import SearchInput from "./SearchInput";
import Table from "./Table/inedx";
import EditButton from "./EditButton";
import DeleteButton from "./DeleteButton";
import Pagination from "./Pagination";
import Input from "./Input";
import UploadButton from "./UploadButton";
import Dropdown from "./Dropdown";
import Modal from "./Modal";
import CalendarBlock from "./Calendar";
import ActionButton from "./ActionButton";
import TagsDropdown from "./TagsDropdown";
import Switch from "./Switcher";
import SearchDropdown from "./SearchDropdown";
import Textarea from "./Textarea";
import ViewButton from "./ViewButton";

export {
  Button,
  LoginInput,
  SearchInput,
  Table,
  EditButton,
  DeleteButton,
  Pagination,
  Input,
  UploadButton,
  Dropdown,
  Modal,
  CalendarBlock,
  ActionButton,
  TagsDropdown,
  Switch,
  SearchDropdown,
  Textarea,
  ViewButton,
};
