import React from "react";

type Props = {
  fill: string;
};

const reports = ({ fill }: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1505_1920"
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="14"
        height="14"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.33398 1.33325H14.6673V14.6666H1.33398V1.33325Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1505_1920)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.10998 2.33325C3.42398 2.33325 2.33398 3.48858 2.33398 5.27725V10.7226C2.33398 12.5113 3.42398 13.6666 5.10998 13.6666H10.8887C12.5767 13.6666 13.6673 12.5113 13.6673 10.7226V5.27725C13.6673 3.48858 12.5767 2.33325 10.89 2.33325H5.10998ZM10.8887 14.6666H5.10998C2.85132 14.6666 1.33398 13.0813 1.33398 10.7226V5.27725C1.33398 2.91859 2.85132 1.33325 5.10998 1.33325H10.89C13.1493 1.33325 14.6673 2.91859 14.6673 5.27725V10.7226C14.6673 13.0813 13.1493 14.6666 10.8887 14.6666Z"
          fill={fill}
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99609 11.1667C7.72009 11.1667 7.49609 10.9427 7.49609 10.6667V8C7.49609 7.724 7.72009 7.5 7.99609 7.5C8.27209 7.5 8.49609 7.724 8.49609 8V10.6667C8.49609 10.9427 8.27209 11.1667 7.99609 11.1667Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00008 6.13607C7.63141 6.13607 7.33008 5.83807 7.33008 5.4694C7.33008 5.10073 7.62541 4.80273 7.99341 4.80273H8.00008C8.36874 4.80273 8.66674 5.10073 8.66674 5.4694C8.66674 5.83807 8.36874 6.13607 8.00008 6.13607Z"
        fill={fill}
      />
    </svg>
  );
};

export default reports;
