import React from "react";

type Props = {
  fill: string;
};

const viewIcon = ({ fill }: Props) => {
  return (
    <svg
      fill={fill}
      width="12"
      height="12"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.001 512.001"
    >
      <g>
        <g>
          <path
            d="M256,155.827c-55.236,0-100.174,44.938-100.174,100.174S200.764,356.175,256,356.175s100.174-44.938,100.174-100.174
           S311.236,155.827,256,155.827z M256,322.783c-36.824,0-66.783-29.959-66.783-66.783s29.959-66.783,66.783-66.783
           s66.783,29.959,66.783,66.783S292.824,322.783,256,322.783z"
          />
        </g>
      </g>
      <g>
        <g>
          <circle cx="256" cy="256.001" r="33.391" />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M508.759,246.114c-1.173-1.597-29.245-39.541-73.893-77.906c-26.439-22.719-53.254-40.873-79.698-53.955
           C321.354,97.526,287.989,89.044,256,89.044c-31.989,0-65.353,8.481-99.168,25.209c-26.444,13.082-53.258,31.235-79.698,53.955
           c-44.646,38.365-72.718,76.31-73.893,77.906c-4.322,5.882-4.322,13.891,0,19.773c1.174,1.596,29.246,39.541,73.893,77.905
           c26.439,22.719,53.254,40.873,79.698,53.955c33.814,16.728,67.179,25.209,99.168,25.209c31.989,0,65.353-8.481,99.168-25.209
           c26.445-13.082,53.259-31.235,79.698-53.955c44.646-38.365,72.72-76.31,73.893-77.906
           C513.082,260.004,513.082,251.996,508.759,246.114z M256,389.566c-100.254,0-191.388-101.137-218.033-133.575
           C64.585,223.527,155.551,122.436,256,122.436c100.254,0,191.388,101.137,218.033,133.575
           C447.416,288.475,356.449,389.566,256,389.566z"
          />
        </g>
      </g>
    </svg>
  );
};

export default viewIcon;
