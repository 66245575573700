import React from "react";
import { Navigate } from "react-router-dom";
import { getToken } from "~utils";
import Dashboard from "./PrivateNavigation/PrivateNavigation";

const PrivateRoute = () => {
  let token = getToken();
  return !!token ? <Dashboard /> : <Navigate to="/login" />;
};

export default PrivateRoute;
