import React from "react";

type Props = {
  fill: string;
};

const arrowDown = ({ fill }: Props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.35225 12.0227C3.55195 12.2224 3.86445 12.2406 4.08466 12.0772L4.14775 12.0227L9 7.17075L13.8523 12.0227C14.052 12.2224 14.3645 12.2406 14.5847 12.0772L14.6477 12.0227C14.8474 11.823 14.8656 11.5105 14.7022 11.2903L14.6477 11.2273L9.39775 5.97725C9.19805 5.77755 8.88555 5.7594 8.66534 5.92279L8.60225 5.97725L3.35225 11.2273C3.13258 11.4469 3.13258 11.8031 3.35225 12.0227Z"
        fill={fill}
      />
    </svg>
  );
};

export default arrowDown;
