import React, { FC, useState, useRef } from "react";

import styles from "./styles.module.scss";
import clsx from "clsx";

import { ArrowDown } from "~assets/svg";
import { useOutsideClick } from "~hooks";
import { Oval } from "react-loader-spinner";

type Props = {
  value?: string;
  onChange?: (val: string) => void;
  label: string;
  placeholder: string;
  error?: string;
  data: string[];
  onPressItem: (val: string, index: number) => void;
  loading?: boolean;
  disable?: boolean;
};

const SearchDropdown: FC<Props> = ({
  label,
  placeholder,
  error,
  data,
  value,
  loading,
  onChange,
  onPressItem,
  disable,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOutsideClick({ ref: dropdownRef, callback: () => setIsOpen(false) });

  return (
    <div ref={dropdownRef}>
      <div className={styles.label}>{label}</div>

      <div
        onClick={() => setIsOpen(!isOpen)}
        className={clsx(styles.field, {
          [styles.input__error]: !!error,
          [styles.active]: isOpen,
        })}
      >
        <input
          disabled={disable}
          value={value}
          placeholder={placeholder}
          onChange={(e) => !!onChange && onChange(e.target.value)}
        />
        <div className={clsx(styles.field__arrow, { [styles.active]: isOpen })}>
          {loading && (
            <Oval
              height={25}
              width={25}
              color="black"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#F5ECE9"
              strokeWidth={6}
              strokeWidthSecondary={6}
            />
          )}
        </div>
      </div>
      <div
        className={clsx(styles.selectBlock, {
          [styles.active60]: isOpen && data.length === 1,
          [styles.active105]: isOpen && data.length === 2,
          [styles.active150]: isOpen && data.length > 2,
        })}
      >
        {data.map((item, index) => (
          <div
            onClick={() => {
              onPressItem(item, index);
              // onSave(item);
              setIsOpen(false);
            }}
            key={`${item}`}
            className={styles.selectBlock__item}
          >
            {item}
          </div>
        ))}
      </div>

      {!!error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default SearchDropdown;
