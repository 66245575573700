import React from "react";

type Props = {
  fill: string;
};

const paginationArrow = ({ fill }: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2909 0.289961L12.7109 1.70996L6.41094 7.99996L12.7109 14.29L11.2909 15.71L3.59094 7.99996L11.2909 0.289961Z"
        fill={fill}
      />
    </svg>
  );
};

export default paginationArrow;
