import React, { FC, useState } from "react";
import styles from "./styles.module.scss";
import { DeleteIcon } from "~assets/svg";
import { Modal } from "~components";
import clsx from "clsx";
type Props = {
  color?: "red" | "green";
  onClick?: () => void;
  subtitle?: string | null;
};
const DeleteButton: FC<Props> = ({ color = "red", onClick, subtitle }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div
        onClick={() => setShowModal(true)}
        className={clsx(styles.button, { [styles.green]: color === "green" })}
      >
        <DeleteIcon fill={color} />
        Delete
      </div>

      {showModal && (
        <Modal
          buttonText={"Delete"}
          onClose={() => setShowModal(false)}
          onClick={() => {
            !!onClick && onClick();
            setShowModal(false);
          }}
        >
          <div className={styles.modal}>
            <div className={styles.modal__title}>
              Are you sure you want to delete it?
            </div>
            {subtitle ? (
              <div className={styles.modal__subtitle}>{subtitle}</div>
            ) : null}
          </div>
        </Modal>
      )}
    </>
  );
};

export default DeleteButton;
