import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { PageLayout } from "~layouts";

import { iconsPng } from "~assets/png";

import {
  SearchInput,
  Table,
  DeleteButton,
  Pagination,
  Modal,
  ActionButton,
  Switch,
  ViewButton,
} from "~components";
import { axiosRequest } from "~api";
import { UserModel } from "~models";
import { LIST_PLACES_TABLE } from "~constants";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { ArrowLeft } from "~assets/svg";
import moment from "moment";

const PAGINATION_LIMIT = 20;
const UserPlaces = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [hidePaginationPage, setHidePaginationPage] = useState(false);
  const [data, setData] = useState<any>({
    count: 0,
    rows: [],
  });

  const onPressDelete = async (index: number) => {
    const list = data.rows[index];
    console.log("list--->", list);
    setData((prev: any) => {
      let state = structuredClone(prev);
      state.rows.splice(index, 1);
      state.count = state.count - 1;
      return state;
    });
    const axios = await axiosRequest();
    axios.delete(`user/places/${list.id}`);
  };

  const getCustomList = async (newPage = 1) => {
    console.log("location------------>", location.state);
    setShowLoader(true);
    const axios = await axiosRequest();
    await axios
      .get(
        `user/places/customList/${location.state.id}?page=${newPage}&limit=${PAGINATION_LIMIT}&sort=-createdAt&q=${searchValue}`
      )
      .then((resp) => {
        setHidePaginationPage(false);
        setShowLoader(false);
        setData(resp.data.data);
      })
      .catch((err) => console.log("getCustomList ==> err", err.response));
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setHidePaginationPage(true);
      getCustomList();
    }, 650);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);
  return (
    <PageLayout>
      <div className={styles.usersManagement}>
        <div
          className={styles.backArrow}
          onClick={() => navigate(`/users/${location.state.user.id}/list`)}
        >
          <ArrowLeft />
        </div>
        <div className={styles.usersManagement__header}>
          <div className={styles.usersManagement__headerTitle}>
            {location?.state?.title}
          </div>
          <div className={styles.usersManagement__headerInput}>
            <SearchInput
              value={searchValue}
              onChange={setSearchValue}
              placeholder={"Search for a custom list"}
            />
          </div>
        </div>

        <Table
          loading={showLoader}
          headers={LIST_PLACES_TABLE}
          items={data.rows}
          renderTableItem={(item, index) => {
            console.log("item-->", item);
            return (
              <tr>
                <td>{moment(item.createdAt).format("DD-MMM-YYYY")}</td>

                <td>{item.title}</td>
                <td>
                  {item.status === "wantToGo" ? "Want To Go" : "Recommended"}
                </td>
                <td
                  title={item.comment}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item.comment !== "" && item.comment ? item.comment : "-"}
                </td>

                <td>
                  <div className={styles.actionsBlock}>
                    <DeleteButton
                      //   subtitle="This will delete all the places inside this list"
                      onClick={() => onPressDelete(index)}
                    />
                  </div>
                </td>
              </tr>
            );
          }}
        />

        <Pagination
          currentPage={page}
          dataLength={data.count}
          paginationLimit={PAGINATION_LIMIT}
          setPage={setPage}
          loadData={getCustomList}
          hidePaginationPage={hidePaginationPage}
        />
      </div>
    </PageLayout>
  );
};

export default UserPlaces;
