import React from "react";

import styles from "./styles.module.scss";

import { PageLayout } from "~layouts";
import {
  Button,
  SearchInput,
  Table,
  EditButton,
  DeleteButton,
  Pagination,
} from "~components";
import { PlusSquare } from "~assets/svg";
import { useNavigate } from "react-router-dom";

const Archtypes = () => {
  const navigate = useNavigate();

  const archtypes = [
    { name: "Trendy scenes", slug: "trendy-scenes" },
    { name: "The Great Outdoors", slug: "the-great-outdoors" },
    { name: "See the sites", slug: "see-the-sites" },
    { name: "Hidden gems", slug: "hidden-gems" },
    { name: "Chill hangs", slug: "chill-hangs" },
    { name: "The finer things", slug: "the-great-outdoors" },
    { name: "Trendy scenes", slug: "trendy-scenes" },
    { name: "The Great Outdoors", slug: "the-great-outdoors" },
    { name: "See the sites", slug: "see-the-sites" },
    { name: "Hidden gems", slug: "hidden-gems" },
    { name: "Chill hangs", slug: "chill-hangs" },
    { name: "The finer things", slug: "the-great-outdoors" },
    { name: "Trendy scenes", slug: "trendy-scenes" },
    { name: "The Great Outdoors", slug: "the-great-outdoors" },
    { name: "See the sites", slug: "see-the-sites" },
    { name: "Hidden gems", slug: "hidden-gems" },
    { name: "Chill hangs", slug: "chill-hangs" },
    { name: "The finer things", slug: "the-great-outdoors" },
    { name: "Trendy scenes", slug: "trendy-scenes" },
    { name: "The Great Outdoors", slug: "the-great-outdoors" },
    { name: "See the sites", slug: "see-the-sites" },
    { name: "Hidden gems", slug: "hidden-gems" },
    { name: "Chill hangs", slug: "chill-hangs" },
    { name: "The finer things", slug: "the-great-outdoors" },
  ];

  return (
    <PageLayout>
      <div className={styles.archtypesBlock}>
        <div className={styles.archtypesBlock__header}>
          <div className={styles.archtypesBlock__headerTitle}>Archtypes</div>

          <div className={styles.archtypesBlock__headerRight}>
            <div className={styles.archtypesBlock__headerButton}>
              <Button
                text="Add archtype"
                mode={"dark"}
                onClick={() => navigate("/archtypes/create")}
                svg={<PlusSquare />}
              />
            </div>

            <div className={styles.archtypesBlock__headerInput}>
              <SearchInput placeholder="Search for a user" />
            </div>
          </div>
        </div>
        <Table
          headers={["NAME", "SLUG", "ACTIONS"]}
          items={archtypes}
          renderTableItem={(item: any) => {
            const { name, slug } = item;
            return (
              <tr>
                <td>
                  <div className={styles.name}>{name}</div>
                </td>
                <td>
                  <div className={styles.slug}>{slug}</div>
                </td>
                <td>
                  <div className={styles.actionsBlock}>
                    <EditButton />
                    <div className={styles.actionsBlock__deleteButton}>
                      <DeleteButton />
                    </div>
                  </div>
                </td>
              </tr>
            );
          }}
        />
        <Pagination />
      </div>
    </PageLayout>
  );
};

export default Archtypes;
