import { ReportType } from "~models";

export const REPORT_TABLE_COLUMNS = {
  [ReportType.activity]: [
    { title: "USER BEING REPORTED", percent: "25%" },
    { title: "REPORTED BY", percent: "20%" },
    { title: "REASON", percent: "20%" },
    { title: "ACTIONS activity" },
  ],
  [ReportType.feedback]: [
    { title: "REPORTED BY", percent: "20%" },
    { title: "REASON", percent: "20%" },
    { title: "USER’S COMMENT", percent: "30%" },
    { title: "ACTIONS feedback" },
  ],
  [ReportType.general]: [
    { title: "REPORTED BY", percent: "20%" },
    { title: "REASON", percent: "20%" },
    { title: "USER’S COMMENT", percent: "30%" },
    { title: "ACTIONS general" },
  ],
  [ReportType.user]: [
    { title: "USER BEING REPORTED", percent: "23%" },
    { title: "REPORTED BY", percent: "23%" },
    { title: "USER’S COMMENT" },
    { title: "ACTIONS", percent: "28%" },
  ],
};

export const USER_TABLE = [
  { title: "FULL NAME", percent: "20%" },
  { title: "EMAIL", percent: "25%" },
  { title: "ACCOUNT STATUS", percent: "20%" },
  { title: "IS ADMIN", percent: "12%" },
  { title: "ACTIONS" },
];

export const LIST_TABLE = [
  { title: "TITLE", percent: "25%" },
  { title: "IS CITY", percent: "13%" },
  { title: "IS FEATURED", percent: "15%" },
  { title: "NO OF PLACES", percent: "20%" },
  { title: "PLACES", percent: "15%" },
  { title: "ACTIONS" },
];

export const LIST_PLACES_TABLE = [
  { title: "DATE", percent: "20%" },
  { title: "TITLE", percent: "23%" },
  { title: "STATUS", percent: "20%" },
  { title: "COMMENT", percent: "25%" },
  { title: "ACTIONS" },
];

export const CATEGORIES_TABLE = [
  { title: "ICON", percent: "35%" },
  { title: "NAME", percent: "35%" },
  { title: "ACTIONS" },
];

export const TAGS_TABLE = [
  { title: "NAME", percent: "35%" },
  { title: "IS ONBOARDING", percent: "35%" },
  { title: "ACTIONS" },
];

export const PLACES_TABLE = [
  { title: "NAME", percent: "15%" },
  { title: "CATEGORY", percent: "15%" },
  { title: "LOCATION", percent: "25%" },
  { title: "USERS RECOMMEND", percent: "15%" },
  { title: "USERS WANT TO GO", percent: "15%" },
  { title: "ACTIONS", percent: "15%" },
];

export const EVENTS_TABLE = [
  { title: "PICTURE", percent: "10%" },
  { title: "NAME", percent: "18%" },
  { title: "LOCATION", percent: "18%" },
  { title: "DESCRIPTION", percent: "19%" },
  { title: "DATE", percent: "19%" },
  { title: "ACTIONS" },
];
