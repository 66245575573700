import React, { FC } from "react";

import styles from "./styles.module.scss";
import clsx from "clsx";

type Props = {
  label: string;
  placeholder: string;
  error?: string;
  value?: string;
  onChange?: (val: string) => void;
  hideLabel?: boolean;
};

const Textarea: FC<Props> = ({
  label,
  placeholder,
  error,
  value,
  onChange,
  hideLabel,
}) => {
  return (
    <div>
      {!hideLabel && <div className={styles.label}>{label}</div>}

      <textarea
        value={value}
        className={clsx(styles.input, { [styles.input__error]: !!error })}
        placeholder={placeholder}
        onChange={(e) =>
          !!onChange && e.target.value.length < 500 && onChange(e.target.value)
        }
      />
      {!!error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default Textarea;
