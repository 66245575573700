import React, { FC, useState, useRef } from "react";

import styles from "./styles.module.scss";
import clsx from "clsx";

import { ArrowDown } from "~assets/svg";
import { useOutsideClick } from "~hooks";
import { Oval } from "react-loader-spinner";

type Props = {
  label: string;
  placeholder: string;
  error?: string;
  data: string[];
  value?: string;
  onSave: (val: string, index: number) => void;
  loading?: boolean;
  isDisable?: boolean;
};

const Dropdown: FC<Props> = ({
  label,
  placeholder,
  error,
  data,
  value,
  onSave,
  loading,
  isDisable,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOutsideClick({ ref: dropdownRef, callback: () => setIsOpen(false) });

  return (
    <div ref={dropdownRef}>
      <div className={styles.label}>{label}</div>

      <div
        onClick={() => !loading && !isDisable && setIsOpen(!isOpen)}
        className={clsx(styles.field, {
          [styles.input__error]: !!error,
          [styles.active]: isOpen,
          [styles.disable]: loading || isDisable,
        })}
      >
        {!!value ? (
          <div className={styles.field__text}>{value}</div>
        ) : (
          <div className={styles.field__placeholder}>{placeholder}</div>
        )}

        {loading ? (
          <Oval
            height={25}
            width={25}
            color="black"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#F5ECE9"
            strokeWidth={6}
            strokeWidthSecondary={6}
          />
        ) : (
          <div
            className={clsx(styles.field__arrow, { [styles.active]: isOpen })}
          >
            <ArrowDown fill={"#AFAFAF"} />
          </div>
        )}
      </div>
      <div
        className={clsx(styles.selectBlock, {
          [styles.active60]: isOpen && data.length === 1,
          [styles.active105]: isOpen && data.length === 2,
          [styles.active150]: isOpen && data.length > 2,
        })}
      >
        {data.map((item, index) => (
          <div
            onClick={() => {
              onSave(item, index);
              setIsOpen(false);
            }}
            key={`${item}`}
            className={styles.selectBlock__item}
          >
            {item}
          </div>
        ))}
      </div>

      {!!error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default Dropdown;
