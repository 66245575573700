// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_switch__efdkC {\n  width: 44px;\n  height: 24px;\n  border-radius: 12px;\n  background-color: #b2b3bd;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  transition: all 0.3s;\n}\n.styles_switch__efdkC.styles_active__EiwY8 {\n  background-color: #55603d;\n}\n.styles_switch__circle__vzCSB {\n  width: 22px;\n  height: 22px;\n  border-radius: 100px;\n  background-color: #FFFFFF;\n  margin-left: 1px;\n  transition: all 0.3s;\n}\n.styles_switch__circle__vzCSB.styles_active__EiwY8 {\n  margin-left: 21px;\n}", "",{"version":3,"sources":["webpack://./src/components/Switcher/styles.module.scss","webpack://./src/scss/vars.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,oBAAA;AADF;AAGE;EACE,yBAAA;AADJ;AAIE;EACE,WAAA;EACA,YAAA;EACA,oBAAA;EACA,yBCVI;EDWJ,gBAAA;EACA,oBAAA;AAFJ;AAII;EACE,iBAAA;AAFN","sourcesContent":["@import \"../../scss/vars.scss\";\n\n.switch {\n  width: 44px;\n  height: 24px;\n  border-radius: 12px;\n  background-color: #b2b3bd;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  transition: all 0.3s;\n\n  &.active {\n    background-color: #55603d;\n  }\n\n  &__circle {\n    width: 22px;\n    height: 22px;\n    border-radius: 100px;\n    background-color: $white;\n    margin-left: 1px;\n    transition: all 0.3s;\n\n    &.active {\n      margin-left: 21px;\n    }\n  }\n}","$black: #1D1D1D;\n$grayMedium: #AFAFAF;\n$gray: #E4E4E4;\n$grayLight: #F8F8F8;\n$grayInactive: #808191;\n$mainColor: #312E29;\n$secondary: #62B273;\n$secondaryInactive: #E9F9F1;\n$background: #F5ECE9;\n$red: #EB5757;\n$white: #FFFFFF;\n$grayText: #AAABAD;\n$primaryText: #2B2D33;\n$tableTH: #84818A;\n$seatle30: #D5D5D6;\n\n$inter: \"Inter\", sans-serif;\n$manrope: \"Manrope\", sans-serif;\n$poppins: \"Poppins\", sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switch": "styles_switch__efdkC",
	"active": "styles_active__EiwY8",
	"switch__circle": "styles_switch__circle__vzCSB"
};
export default ___CSS_LOADER_EXPORT___;
