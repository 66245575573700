import React, { FC, useState } from "react";
import styles from "./styles.module.scss";
import { Modal } from "~components";

import clsx from "clsx";
type Props = {
  color?: "red" | "green" | "blue";
  onClick?: () => void;
  text?: string;
  isDisable?: boolean;
  modalText?: string;
};
const ActionButton: FC<Props> = ({
  color = "red",
  text,
  onClick,
  isDisable,
  modalText,
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div
        onClick={
          isDisable
            ? () => {}
            : !!modalText
            ? () => setShowModal(true)
            : onClick
        }
        className={clsx(styles.button, [
          {
            [styles.disable]: isDisable,
          },
          {
            [styles.green]: color === "green",
          },
          {
            [styles.blue2]: color === "blue",
          },
        ])}
      >
        {text}
      </div>

      {showModal && (
        <Modal
          buttonText={text}
          onClose={() => setShowModal(false)}
          onClick={() => {
            !!onClick && onClick();
            setShowModal(false);
          }}
        >
          <div className={styles.modal}>
            <div className={styles.modal__title}>{modalText}</div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ActionButton;
