import React, { FC, useCallback } from "react";

import styles from "./styles.module.scss";
import { PaperClip } from "~assets/svg";
import { useDropzone } from "react-dropzone";

type Props = {
  text: string;
  type: "image" | "file";
  saveFile: (file: any) => any;
};

const UploadButton: FC<Props> = ({ text, type, saveFile }) => {
  const onDrop = useCallback((acceptedFiles: any) => {
    console.log("acceptedFiles", acceptedFiles);
    saveFile(acceptedFiles[0]);
    // Do something with the files
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept:
      type === "image"
        ? {
            "image/jpeg": [],
            "image/png": [],
          }
        : {
            "text/csv": [],
          },
  });

  return (
    <div className={styles.button} {...getRootProps()}>
      <input {...getInputProps()} />
      <PaperClip />
      {text}
    </div>
  );
};

export default UploadButton;
