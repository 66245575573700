import React from "react";

const editPen = () => {
  return (
    <svg
      width="11"
      height="18"
      viewBox="0 0 11 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3864_437)">
        <path
          d="M10.7971 1.55936L8.18716 0.0515153C8.09633 4.27217e-05 7.98733 -0.0120685 7.88438 0.0121539C7.78144 0.0394041 7.69363 0.106016 7.63913 0.199878L6.88521 1.50486C6.77318 1.69561 6.83979 1.94389 7.03357 2.05289L9.64353 3.56074C9.70409 3.59707 9.77373 3.61524 9.84337 3.61524C9.8797 3.61524 9.91301 3.61221 9.94631 3.60313C10.0493 3.57588 10.1371 3.50926 10.1916 3.4154L10.9455 2.11042C11.0575 1.91664 10.9909 1.67139 10.7971 1.55936Z"
          fill="black"
        />
        <path
          d="M9.01663 16.0959H1.94066L3.43336 15.1088C3.43942 15.1058 3.44245 15.0997 3.4485 15.0967C3.45759 15.0906 3.46364 15.0846 3.47272 15.0755C3.503 15.0482 3.53025 15.018 3.55145 14.9847C3.55448 14.9786 3.56053 14.9786 3.56053 14.9725L9.43749 4.71738C9.54649 4.52663 9.48291 4.28138 9.28913 4.16935L6.68219 2.66453C6.59136 2.61003 6.47933 2.59792 6.37638 2.62517C6.27344 2.65242 6.18563 2.72206 6.13416 2.81289L0.254173 13.0681C0.251145 13.0741 0.251145 13.0771 0.248117 13.0832C0.22995 13.1195 0.214811 13.1589 0.208756 13.2013C0.205728 13.2104 0.205728 13.2194 0.2027 13.2285C0.2027 13.2346 0.199672 13.2376 0.199672 13.2437L-0.000162472 16.6076C-0.00621807 16.7226 0.0361711 16.8346 0.117922 16.9134C0.211784 17.0648 0.378313 17.1647 0.569064 17.1647H9.01663C9.31335 17.1647 9.55255 16.9255 9.55255 16.6287C9.55255 16.332 9.31032 16.0959 9.01663 16.0959Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_3864_437">
          <rect width="11" height="17.1646" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default editPen;
