import React, { useState } from "react";

import { PageLayout } from "~layouts";
import { ArrowLeft } from "~assets/svg";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Input, UploadButton } from "~components";

import styles from "./styles.module.scss";
import { CategoryModel } from "models/CategoryModel";
import { axiosRequest } from "~api";
import { BASE_URL } from "~constants";

type ImageType = {
  uri: string;
  name: string;
  type: string;
};

const EditCategory = ({}) => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location", location.state);
  const [file, setFile] = useState<any>(null);

  const [title, setTitle] = useState<string>(location.state.category.title);

  const [showLoader, setShowLoader] = useState(false);

  console.log("file", file);

  const uploadImageOnServer = async (image: ImageType) => {
    const axios = await axiosRequest(true);
    const formData = new FormData();

    // let uri = await (await uploadImageOnServer(image)).data.data;

    formData.append("file", file);

    return await axios({
      method: "POST",
      url: `${BASE_URL}/files/upload`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer kf71b2ayZPDr2H+L05RjDifnRru6TEruRQR6oEs7ovc`,
      },
    }).catch((err) => console.log("ERR", err.response));
  };

  const onPressSave = async () => {
    setShowLoader(true);
    const data: { title?: string; icon?: string } = {};
    if (title !== location.state.category.title) {
      data.title = title;
    }

    if (file !== null) {
      let uri = await await uploadImageOnServer(file);
      data.icon = uri?.data.data;
    }

    const axios = await axiosRequest();
    axios.put(`categories/${location.state.category.id}`, data).then(() => {
      setShowLoader(false);
      navigate("/categories");
    });
  };

  const objectURL = !!file ? URL.createObjectURL(file) : "";

  const isDisable =
    title.length === 0 ||
    (title === location.state.category.title && file === null);

  return (
    <PageLayout>
      <div className={styles.createCategory}>
        <div className={styles.createCategory__title}>Add new category</div>
        <div
          className={styles.createCategory__backArrow}
          onClick={() => navigate("/categories")}
        >
          <ArrowLeft />
        </div>

        <div className={styles.createCategory__input}>
          <Input
            value={title}
            label="Name"
            placeholder="Category name"
            onChange={setTitle}
          />
        </div>

        {!!file ? (
          <div className={styles.createCategory__image}>
            <img src={objectURL} alt={"Item image"} />
          </div>
        ) : (
          <UploadButton text="Add icon" type={"image"} saveFile={setFile} />
        )}

        <div className={styles.createCategory__bottom}>
          <div className={styles.createCategory__bottomButton}>
            <Button
              loading={showLoader}
              disable={isDisable}
              text="Save"
              mode={"dark"}
              onClick={() => onPressSave()}
            />
          </div>
          <div className={styles.createCategory__bottomButton}>
            <Button
              text="Cancel"
              mode={"light"}
              disable={!file && title === location.state.category.title}
              onClick={() => {
                setFile(null);
                setTitle(location.state.category.title);
              }}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default EditCategory;
